export const CODES = {
  LOCATIONS: {
    IN_CENTER: "IN_CENTER",
    ONLINE_VIRTUAL: "ONLINE_VIRTUAL"
  },
  APPOINTMENT_STATUSES: {
    RESCHEDULED: "RESCHEDULED",
    AS_SCHEDULED: "AS_SCHEDULED",
    CANCELLED: "CANCELLED",
    NO_SHOW: "NO_SHOW",
    SCHEDULED: "SCHEDULED"
  },
  REFERRAL_SOURCES: {
    OTHER: "OTHER"
  }
};
