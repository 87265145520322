import React, { useState, forwardRef, useId, useEffect } from "react";
import classNames from "classnames";
import Dropdown from "react-bootstrap/Dropdown";
import { useSelect } from "downshift";

interface GenericMultiSelectDropdownProps<T> {
  items: T[];
  getLabel: (item: T | null | undefined) => string;
  getValue: (item: T | null | undefined) => string;
  onChange?: (selectedItems: T[]) => void;
  onBlur?: (selectedItems: T[]) => void;
  selectedItems?: T[];
  toggleLabel: string;
  containerClassName?: string;
  disabled?: boolean;
  id?: string;
}

export const GenericMultiSelectDropdown = <T extends {}>({
  items,
  getLabel,
  getValue,
  onChange,
  onBlur,
  selectedItems = [],
  toggleLabel,
  containerClassName,
  disabled = false,
  id
}: GenericMultiSelectDropdownProps<T>) => {
  const [selected, setSelected] = useState<T[]>(selectedItems);
  useEffect(() => {
    setSelected(items.filter(item => selectedItems.find(selected => getValue(selected) === getValue(item))));
  }, [selectedItems, items]);
  const randomId = useId();
  const baseId = id ?? randomId;
  const toggleId = `toggle-${baseId}`;
  const checkboxId = `checkboxInput${baseId}`;

  const { isOpen, getToggleButtonProps, getMenuProps, getItemProps, highlightedIndex } = useSelect<T>({
    items,
    selectedItem: null,
    stateReducer: (_state, { changes, type }) => {
      switch (type) {
        case useSelect.stateChangeTypes.ToggleButtonKeyDownEnter:
        case useSelect.stateChangeTypes.ToggleButtonKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: true, // keep the menu open after selection.
            highlightedIndex: items.findIndex(item => getValue(item) === getValue(changes.selectedItem))
          };
      }
      return changes;
    },
    onStateChange: ({ type, selectedItem, isOpen }) => {
      switch (type) {
        case useSelect.stateChangeTypes.ToggleButtonKeyDownEnter:
        case useSelect.stateChangeTypes.ToggleButtonKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:
          if (selectedItem) {
            if (selected.includes(selectedItem)) {
              const newSelected = selected.filter(item => item !== selectedItem);
              setSelected(newSelected);
              onChange?.(newSelected);
            } else {
              const newSelected = [...selected, selectedItem];
              setSelected(newSelected);
              onChange?.(newSelected);
            }
          }
          break;
        case useSelect.stateChangeTypes.ToggleButtonBlur:
          if (!isOpen) {
            setSelected(selected);
            onBlur?.(selected);
          }
          break;
        default:
          break;
      }
    }
  });

  const renderSelectedItems = () => {
    return selected.map(item => getLabel(item)).join(", ");
  };

  return (
    <div className={classNames("form-floating", containerClassName)}>
      <Dropdown className="form-input">
        <Dropdown.Toggle
          {...getToggleButtonProps({ className: classNames("form-select", { show: isOpen }), disabled, id: toggleId })}
          as={GenericMultiSelectDropdownToggle}
          label={toggleLabel}
          data-testid={`toggle-${baseId}`}
        >
          {renderSelectedItems()}
        </Dropdown.Toggle>
        <Dropdown.Menu
          {...getMenuProps({ show: isOpen }, { suppressRefError: true })}
          as={GenericMultiSelectDropdownMenu}
        >
          {items.map((item, index) => (
            <Dropdown.Item
              {...getItemProps({
                item,
                index,
                active: highlightedIndex === index,
                onClick: event => {
                  event.stopPropagation();
                  event.preventDefault();
                }
              })}
              key={getValue(item)}
              as={GenericMultiSelectDropdownItem}
            >
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={selected.includes(item)}
                  id={`${checkboxId}${getValue(item)}`}
                  readOnly
                />
                <label className="form-check-label" htmlFor={`${checkboxId}${getValue(item)}`}>
                  {getLabel(item)}
                </label>
              </div>
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      {/* <Dropdown>
      <Dropdown.Toggle {...getToggleButtonProps()}>
        {selected.length > 0 ? renderSelectedItems() : "Select items"}
      </Dropdown.Toggle>
      <Dropdown.Menu {...getMenuProps()}>
        {isOpen &&
          items.map((item, index) => (
            <Dropdown.Item key={getValue(item)} {...getItemProps({ item, index })} active={highlightedIndex === index}>
              <input type="checkbox" checked={selected.includes(item)} readOnly className="mr-2" />
              {getLabel(item)}
            </Dropdown.Item>
          ))}
      </Dropdown.Menu>
    </Dropdown> */}
    </div>
  );
};

interface GenericMultiSelectDropdownToggleProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  label: string;
}

const GenericMultiSelectDropdownToggle = forwardRef<HTMLDivElement, GenericMultiSelectDropdownToggleProps>(
  ({ label, children, ...props }, ref) => {
    return (
      <div className={classNames("form-floating")}>
        <div {...props} ref={ref} style={{ textOverflow: "ellipsis", display: "block", overflow: "hidden" }}>
          {children}
        </div>
        <label htmlFor={props.id}>{label}</label>
      </div>
    );
  }
);

GenericMultiSelectDropdownToggle.displayName = "GenericMultiSelectDropdownToggle";

interface GenericMultiSelectDropdownMenuProps extends React.HtmlHTMLAttributes<HTMLUListElement> {
  close?: boolean;
  show?: boolean;
}

const GenericMultiSelectDropdownMenu = forwardRef<HTMLUListElement, GenericMultiSelectDropdownMenuProps>(
  ({ close, show, ...props }, ref) => {
    return <ul {...props} ref={ref} />;
  }
);

GenericMultiSelectDropdownMenu.displayName = "GenericMultiSelectDropdownMenu";

const GenericMultiSelectDropdownItem = forwardRef<HTMLLIElement, React.HtmlHTMLAttributes<HTMLLIElement>>(
  ({ ...props }, ref) => {
    return <li {...props} ref={ref} />;
  }
);

GenericMultiSelectDropdownItem.displayName = "GenericMultiSelectDropdownItem";
