import classnames from "classnames";
import { useMemo, type FC, useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";

import * as Permissions from "../data/permissions";
import { useCenterStaff } from "../hooks/use-center";
import type { InquiryStatus } from "../hooks/use-inquiries";
import { useInquiryList } from "../hooks/use-inquiries/use-inquiry-list";
import { useAccessControl } from "./access-control-context";
import { AccessControlGate } from "./access-control-gate";
import { useInquiryTabActive } from "./utilities/use-inquiry-tab-active";

export const SIDEBAR_MENU_TRIGGER_EVENT = "sidebar-menu-trigger-event";
export interface SidebarMenuTriggerEvent {
  show?: boolean;
}
export const dispatchSidebarMenuTriggerEvent = (show?: boolean) => {
  document.dispatchEvent(new CustomEvent<SidebarMenuTriggerEvent>(SIDEBAR_MENU_TRIGGER_EVENT, { detail: { show } }));
};

export const InquirySubmenuItem: FC<{
  title: string;
  count?: number;
  to: string;
  statuses: InquiryStatus[];
}> = ({ title, count, statuses, to }) => {
  const location = useLocation();
  const active = useInquiryTabActive(location.search, statuses);
  return <SubmenuItem title={title} count={count} to={to} active={active}></SubmenuItem>;
};

export const SubmenuItem: React.FC<{
  title: string;
  count?: number;
  active: boolean;
  to: string;
}> = ({ title, count, active, to }) => {
  return (
    <li>
      <Link to={to} className={classnames({ active })}>
        {title}
        {count !== undefined && <span className="badge">{count}</span>}
      </Link>
    </li>
  );
};

export const AppMenu: React.FC = () => {
  const location = useLocation();
  const { activeCenterId, hasPermission, hasFeatureAccess } = useAccessControl();
  const { staff } = useCenterStaff(activeCenterId, hasPermission && hasPermission(Permissions.STAFF_VIEW));

  const currentStaffCount = useMemo(() => staff?.filter(s => s.isActive).length ?? 0, [staff]);
  const inactiveStaffCount = useMemo(() => staff?.filter(s => !s.isActive).length ?? 0, [staff]);

  const { data: { statusesCount } = {} } = useInquiryList(
    {},
    hasFeatureAccess?.("inquiries") && hasPermission?.(Permissions.STUDENT_MANAGEMENT_VIEW)
  );

  const [showMenu, setShowMenu] = useState<boolean>(false);

  useEffect(() => {
    const handleSidebarTrigger = (event: CustomEvent<SidebarMenuTriggerEvent>) => {
      if (event.detail.show !== undefined) {
        setShowMenu(event.detail.show);
      } else {
        setShowMenu(previous => !previous);
      }
    };
    document.addEventListener(SIDEBAR_MENU_TRIGGER_EVENT, handleSidebarTrigger);
    return () => {
      document.removeEventListener(SIDEBAR_MENU_TRIGGER_EVENT, handleSidebarTrigger);
    };
  }, []);

  useEffect(() => {
    setShowMenu(false);
  }, [location.pathname, location.search]);

  return (
    <>
      <aside
        className={classnames("col col-sidebar-primary collapse", { show: showMenu })}
        id="col-sidebar-primary"
        data-testid="col-sidebar-primary"
      >
        <div className="content-pad">
          <nav className="nav-primary" aria-label="Main Navigation">
            <div className="accordion" id="nav-primary">
              {hasFeatureAccess && hasFeatureAccess("dashboard") && (
                <div className="accordion-item">
                  <h2 className="accordion-header" id="dashboard-menu-header">
                    <Link
                      className={classnames("accordion-button", {
                        collapsed: !location.pathname.startsWith("/dashboard")
                      })}
                      to={"/dashboard"}
                    >
                      <span className="fas fa-gauge-high" aria-hidden="true"></span>
                      Dashboard
                    </Link>
                  </h2>
                  <div
                    id="dashboard-menu-header-collapsible-panel"
                    className={classnames("accordion-collapse", {
                      collapse: !location.pathname.startsWith("/dashboard")
                    })}
                    aria-labelledby="dashboard-menu-header"
                    data-bs-parent="#nav-primary"
                  >
                    {hasFeatureAccess && hasFeatureAccess("appointment_calendar") && (
                      <div className="accordion-body">
                        <ul className="list-unstyled">
                          <SubmenuItem
                            title="Calendar"
                            active={location.pathname.startsWith("/dashboard/calendar")}
                            to="/dashboard/calendar"
                          />
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {hasFeatureAccess && hasFeatureAccess("inquiries") && (
                <AccessControlGate required={Permissions.STUDENT_MANAGEMENT_VIEW}>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="inquiry-menu-header">
                      <Link
                        className={classnames("accordion-button", {
                          collapsed: !location.pathname.startsWith("/inquiry")
                        })}
                        to={"/inquiry"}
                      >
                        <span className="fas fa-list" aria-hidden="true"></span>
                        Students
                      </Link>
                    </h2>
                    <div
                      id="inquiry-menu-header-collapsible-panel"
                      className={classnames("accordion-collapse", {
                        collapse: !location.pathname.startsWith("/inquiry")
                      })}
                      aria-labelledby="inquiry-menu-header"
                      data-bs-parent="#nav-primary"
                    >
                      <div className="accordion-body">
                        <ul className="list-unstyled">
                          <InquirySubmenuItem
                            title="Inquiries"
                            count={statusesCount?.INQ ?? 0}
                            to={"/inquiry?status=INQ"}
                            statuses={["INQ"]}
                          />
                          <InquirySubmenuItem
                            title="Academic Evaluation"
                            count={statusesCount?.AE ?? 0}
                            to={"/inquiry?status=AE"}
                            statuses={["AE"]}
                          />
                          <InquirySubmenuItem
                            title="Consult/Conference"
                            count={statusesCount?.CONS_CONF ?? 0}
                            to={"/inquiry?status=CONS_CONF"}
                            statuses={["CONS_CONF"]}
                          />
                          <InquirySubmenuItem
                            title="Enrolled"
                            count={statusesCount?.ENR ?? 0}
                            to={"/inquiry?status=ENR"}
                            statuses={["ENR"]}
                          />
                          <InquirySubmenuItem
                            title="Dropped"
                            count={statusesCount?.DROP ?? 0}
                            to={"/inquiry?status=DROP"}
                            statuses={["DROP"]}
                          />
                        </ul>
                      </div>
                    </div>
                  </div>
                </AccessControlGate>
              )}
              <AccessControlGate required={Permissions.STAFF_VIEW}>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="staff-menu-header">
                    <Link
                      className={classnames("accordion-button", {
                        collapsed: !location.pathname.startsWith("/staff-administration")
                      })}
                      to={"/staff-administration/active"}
                    >
                      <span className="fas fa-id-card-alt" aria-hidden="true"></span>
                      Staff
                    </Link>
                  </h2>
                  <div
                    id="staff-menu-header-collapsible-panel"
                    className={classnames("accordion-collapse", {
                      collapse: !location.pathname.startsWith("/staff-administration")
                    })}
                    aria-labelledby="staff-menu-header"
                    data-bs-parent="#nav-primary"
                  >
                    <div className="accordion-body">
                      <ul className="list-unstyled">
                        <SubmenuItem
                          title="Active"
                          count={currentStaffCount}
                          active={location.pathname.startsWith("/staff-administration/active")}
                          to="/staff-administration/active"
                        />
                        <SubmenuItem
                          title="Inactive"
                          count={inactiveStaffCount}
                          active={location.pathname.startsWith("/staff-administration/inactive")}
                          to="/staff-administration/inactive"
                        />
                      </ul>
                    </div>
                  </div>
                </div>
              </AccessControlGate>
              <div className="accordion-item">
                <h2 className="accordion-header" id="centers-menu-header">
                  <Link
                    className={classnames("accordion-button", {
                      collapsed: !location.pathname.startsWith("/centers")
                    })}
                    to={"/centers"}
                  >
                    <span className="fas fa-school" aria-hidden="true"></span>
                    Center
                  </Link>
                </h2>

                <div
                  id="centers-menu-header-collapsible-panel"
                  className={classnames("accordion-collapse", {
                    collapse: !location.pathname.startsWith("/centers")
                  })}
                  aria-labelledby="centers-menu-header"
                  data-bs-parent="#nav-primary"
                >
                  <div className="accordion-body">
                    <ul className="list-unstyled">
                      <SubmenuItem
                        title="Contact"
                        to={"/centers/contact"}
                        active={location.pathname.startsWith("/centers/contact")}
                      />
                      {hasPermission &&
                        hasPermission([
                          Permissions.CENTER_MANAGE_OPERATIONAL_SCHEDULE,
                          Permissions.CENTER_VIEW_OPERATIONAL_SCHEDULE
                        ]) && (
                          <SubmenuItem
                            title="Operating Hours"
                            to={"/centers/hours"}
                            active={location.pathname.startsWith("/centers/hours")}
                          />
                        )}
                      <SubmenuItem
                        title="Notes"
                        to={"/centers/notes"}
                        active={location.pathname.startsWith("/centers/notes")}
                      />
                      {hasPermission &&
                        hasPermission(Permissions.CENTER_EDIT) &&
                        hasFeatureAccess &&
                        hasFeatureAccess("consumer_website") && (
                          <SubmenuItem
                            title="Website"
                            to={"/centers/website"}
                            active={location.pathname.startsWith("/centers/website")}
                          />
                        )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </aside>
      <div className="dim-primary-sidebar"></div>
    </>
  );
};
