import { zodResolver } from "@hookform/resolvers/zod";
import moment from "moment";
import { type FC, useEffect, useMemo, useState } from "react";
import { Col } from "react-bootstrap";
import { Controller, type UseFormReturn, useForm } from "react-hook-form";
import { z } from "zod";

import { useAccessControl } from "../../app/use-access-control";
import { InputField } from "../../components/forms/input-field";
import { TextAreaField } from "../../components/forms/text-area-field";
import { LoadingButton } from "../../components/loading-button/loading-button";
import { useFormModal } from "../../components/modal/modal";
import { MultiSelect } from "../../components/search-combobox/multi-select";
import { MultiSelectSearch } from "../../components/search-combobox/multi-select-search";
import { useCenterStaff } from "../../hooks/use-center";
import { useDebounce } from "../../hooks/use-debounce";
import type { DashboardContact } from "../../hooks/use-inquiries";
import { useUpsertPhoneActivity } from "../../hooks/use-inquiries/use-upsert-phone-activity";
import type { TimelineEvent } from "../../hooks/use-inquiries/use-timeline";
import type { BasicFormProps } from "../form-context-wrapper/form-context-wrapper";

const phoneActivityFormSchema = z.object({
  id: z.string().optional(),
  subsequentDate: z.string().trim().min(1, "Select a day"),
  subsequentTime: z.string().trim().min(1, "Select a time"),
  selectedAttendees: z
    .array(z.object({ value: z.number().positive(), label: z.string().optional() }))
    .min(1, "Select an attendee"),
  selectedStaff: z
    .array(z.object({ value: z.coerce.string(), label: z.string().optional() }))
    .min(1, "Select a staff member"),
  subsequentNotes: z.string().trim().min(1, "Enter notes")
});

type PhoneActivityFormValues = z.infer<typeof phoneActivityFormSchema>;

export const PhoneActivityForm: FC<
  BasicFormProps<PhoneActivityFormValues> & { attendees: { value: string | number; label: string }[] }
> = ({ attendees, methods }) => {
  const {
    control,
    register,
    formState: { errors },
    setValue
  } = methods ?? ({ formState: {} } as UseFormReturn<PhoneActivityFormValues>);

  useEffect(() => {
    setValue("subsequentDate", moment(new Date()).format("yyyy-MM-DD"));
    setValue("subsequentTime", moment(new Date()).format("HH:mm"));
  }, []);

  const { activeCenterId } = useAccessControl();
  const [staffSearchValue, setStaffSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(staffSearchValue, 350);

  const { staff } = useCenterStaff(activeCenterId);

  const activeStaff = useMemo(() => {
    return staff?.filter(s => s.isActive);
  }, [staff]);

  return (
    <>
      <div className="row form-standard">
        <div className="col col-12 col-lg-6">
          <div className="form-floating">
            <InputField
              label={"Date"}
              type="date"
              className="form-control"
              error={errors.subsequentDate}
              validationState={errors.subsequentDate ? "error" : undefined}
              {...register("subsequentDate")}
            />
          </div>
        </div>
        <div className="col col-12 col-lg-6">
          <div className="form-floating">
            <InputField
              label={"Time"}
              type="time"
              className="form-control"
              error={errors.subsequentTime}
              validationState={errors.subsequentTime ? "error" : undefined}
              {...register("subsequentTime")}
            />
          </div>
        </div>
        <div className="col col-12 col-lg-6">
          <Controller
            control={control}
            name="selectedAttendees"
            render={({ field: { value, onChange }, fieldState }) => (
              <MultiSelect
                label="Attendees"
                selectedValues={value?.map(value => value.value)}
                onChangeSelectedItems={onChange}
                options={attendees}
                error={fieldState.error}
              />
            )}
          />
        </div>
        <div className="col col-12 col-lg-6">
          <Controller
            control={control}
            name="selectedStaff"
            render={({ field: { value, onChange }, fieldState }) => (
              <MultiSelectSearch
                label="Staff"
                selectedValues={value?.map(value => value.value)}
                onChangeSelectedItems={onChange}
                options={(activeStaff || [])?.map(member => ({
                  value: member.id,
                  label: member.displayName
                }))}
                filteredOptions={(activeStaff || [])
                  .filter(member => member.displayName.toLowerCase().includes(debouncedSearchValue.toLowerCase()))
                  .map(member => ({ value: member.id, label: member.displayName }))}
                inputValue={staffSearchValue}
                onChangeInputValue={setStaffSearchValue}
                error={fieldState.error}
              />
            )}
          />
        </div>
      </div>
      <div className="col col-12">
        <div className="form-floating">
          <TextAreaField
            disableColClass
            error={errors.subsequentNotes}
            validationState={errors.subsequentNotes ? "error" : undefined}
            label="Notes"
            className="form-control"
            id="subsequentNotes"
            style={{ height: "200px" }}
            {...register("subsequentNotes")}
          />
        </div>
      </div>
    </>
  );
};

export const usePhoneActivityForm = () =>
  useForm<PhoneActivityFormValues>({
    defaultValues: {
      subsequentDate: "",
      subsequentTime: "",
      selectedAttendees: [],
      selectedStaff: [],
      subsequentNotes: ""
    },
    resolver: zodResolver(phoneActivityFormSchema)
  });

interface UsePhoneActivityFormModalProps {
  phoneActivity: TimelineEvent | null;
  onHideModal: () => void;
  contacts: DashboardContact[];
  inquiryId: string;
}

export const usePhoneActivityModal = ({
  phoneActivity,
  onHideModal,
  inquiryId,
  contacts
}: UsePhoneActivityFormModalProps) => {
  const upsertPhoneActivityMutation = useUpsertPhoneActivity({
    inquiryId,
    phoneActivityEventId: phoneActivity?.id
  });
  const handleSubmit = ({
    subsequentTime,
    selectedStaff,
    subsequentNotes,
    subsequentDate,
    selectedAttendees
  }: PhoneActivityFormValues) => {
    upsertPhoneActivityMutation.mutate(
      {
        startDateTime: moment(`${subsequentDate} ${subsequentTime}`).toISOString(),
        attendees: selectedAttendees.map(({ value }) => value),
        staffMembers: selectedStaff.map(({ value }) => value),
        notes: subsequentNotes
      },
      { onSuccess: onHide }
    );
  };

  const { show, hide, renderModal, formId } = useFormModal({
    name: phoneActivity ? "Update Phone Activity" : "Create Phone Activity",
    useFormProps: {
      values: phoneActivity
        ? {
            subsequentDate: moment(phoneActivity.startDateTime).format("yyyy-MM-DD"),
            subsequentTime: moment(phoneActivity.startDateTime).format("HH:mm"),
            selectedAttendees: phoneActivity.attendees.map(({ id, displayName }) => ({
              value: id,
              label: displayName
            })),
            selectedStaff: phoneActivity.staffMembers.map(({ id, displayName }) => ({ value: id, label: displayName })),
            subsequentNotes: phoneActivity.notes
          }
        : {
            subsequentDate: moment(new Date()).format("yyyy-MM-DD"),
            subsequentTime: moment(new Date()).format("HH:mm"),
            selectedAttendees: [],
            selectedStaff: [],
            subsequentNotes: ""
          },
      resolver: zodResolver(phoneActivityFormSchema),
      mode: "onTouched"
    },
    onSubmit: handleSubmit
  });

  const onHide = () => {
    hide();
    onHideModal();
  };

  return {
    showPhoneActivityModal: show,
    hidePhoneActivityModal: onHide,
    renderPhoneActivityModal: () =>
      renderModal({
        title: "Create Phone Activity",
        backdrop: "static",
        centered: true,
        size: "lg",
        body: (
          <PhoneActivityForm
            attendees={contacts.map(contact => ({ value: contact.id, label: contact.displayName })) ?? []}
          />
        ),
        footer: (
          <div className="row-reverse d-flex">
            <Col>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={onHide}
                disabled={upsertPhoneActivityMutation.isLoading}
              >
                Cancel
              </button>
              &nbsp;
              <LoadingButton
                type="submit"
                form={formId}
                className="btn btn-primary"
                loadingMessage="Saving..."
                isLoading={upsertPhoneActivityMutation.isLoading}
              >
                Save
              </LoadingButton>
            </Col>
          </div>
        ),
        onHide
      })
  };
};
