import classNames from "classnames";
import { useEffect, useMemo, type FC } from "react";
import { CardBody } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useWatch, type UseFormReturn } from "react-hook-form";
import { z } from "zod";

import { useAccessControl } from "../../app/use-access-control";
import { useCenterConstants, type ServiceDictionary, type SubServiceDictionary } from "../../hooks/use-center";
import { familyContactSchema, useFamilyConstants, type FamilyContact } from "../../hooks/use-families";
import { useInquiryConstants, type InquiryFormData } from "../../hooks/use-inquiries";
import { CheckboxField, CheckboxFieldset } from "../forms/checkbox-field";
import { InputField } from "../forms/input-field";
import { SelectField } from "../forms/select-field";
import { ContactSearchController } from "../search-combobox/contact-search";

export const discountTypeCodes = new Map([
  ["Dollar", "$"],
  ["Percent", "%"],
  ["$", "Dollar"],
  ["%", "Percent"]
]);

const dollarsSchema = z.coerce
  .string()
  // Input type="number" allows for '+', '-', 'e'.  e.g. +1e-2 = 0.01. When it has those characters
  // and it cannot be represented as a number it's value = "". So we need to check to make sure that
  // the value is not "". the following !isNaN(Number(value)) check is NOT necessary as the input
  // already checks this but left there for some sanity
  .refine(value => value && !isNaN(Number(value)))
  .transform(value => Number(value))
  .pipe(
    z
      .number()
      .min(0, { message: "Value must be positive" })
      .lte(99999.99, { message: "Value must be less than or equal to 99999.99" })
      .step(0.01, { message: "Value must be in increments of 0.01" })
  );

// split the services validation into its own z object and do an interseciton w/ inquirySchema
// if we put it in the same object as the inquirySchema the validations for in superRefine for
// subject/test do not happen until all the validations in the entire inquirySchema object pass
const servicesSchema = z
  .object({
    serviceId: z.string().min(1, { message: "service is required" }),
    selectedSubService: z.string().min(1, { message: "subservice is required" }),
    selectedSubjectOrTest: z.string().default("")
  })
  .superRefine(({ selectedSubService, selectedSubjectOrTest }, ctx) => {
    if (selectedSubService) {
      const [, totalLength] = selectedSubService.split("::");

      if (Number(totalLength) > 0 && !selectedSubjectOrTest) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Subject or test is required subject test",
          path: ["selectedSubjectOrTest"]
        });
      }
    }
  });

export const inquirySchema = z
  .object({
    id: z.string().optional(),
    inquirer: familyContactSchema,
    student: familyContactSchema,
    family: z.object({ id: z.string() }).passthrough().nullable(),
    relationshipId: z.string().min(1, { message: "Relationship is required" }),
    aeFee: z
      .object({
        baseFee: dollarsSchema,
        discountValue: dollarsSchema,
        discountReasonId: z.string(),
        discountType: z.string().optional()
      })
      .passthrough()
      .optional(),
    standardHourlyTuitionQuoted: dollarsSchema,
    privateHourlyTuitionQuoted: dollarsSchema,
    callbackSpecialQuoted: dollarsSchema,

    takenBy: z.object({ id: z.string() }), //dont need to pass through
    sourceId: z.string().min(1, { message: "Contact Method is required" }),
    referredById: z.string().min(1, { message: "Referred By is required" }),
    inquiryDate: z.string(),
    inquiryTimeReceived: z.string(),
    durationMinutes: z.coerce
      .number()
      .min(0, { message: "Must be a postive number" })
      .max(2147483647, { message: "Must be less than or equal to 2147483647" })
      .optional()
  })
  .passthrough()
  .superRefine(({ aeFee }, ctx) => {
    if (aeFee?.discountType && aeFee?.discountValue && !aeFee?.discountReasonId) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Discount reason is required",
        path: ["aeFee", "discountReasonId"]
      });
    }
  })
  .and(servicesSchema)
  .transform(input => {
    const { selectedSubService, selectedSubjectOrTest } = input;
    const [subServiceId] = selectedSubService.split("::");
    const [subjectOrTestId, subjectOrTest] = selectedSubjectOrTest.split("::");

    return {
      ...input,
      inquirerId: input.inquirer.id,
      studentId: input.student.id,
      familyId: input.family?.id === undefined ? undefined : input.family?.id ? input.family.id : null,
      inquiryDate: `${input.inquiryDate}T${input.inquiryTimeReceived}Z`,
      serviceId: input.serviceId || undefined,
      programId: subServiceId || undefined,
      testId: subjectOrTest === "testId" ? subjectOrTestId : undefined,
      subjectId: subjectOrTest === "subjectId" ? subjectOrTestId : undefined,
      aeFee: {
        ...input.aeFee,
        discountReasonId: input.aeFee?.discountReasonId || undefined
      },
      takenById: input.takenBy.id
    };
  });

export interface InquiryFormLeftPaneProps {
  onSelectInquirer: (contact: FamilyContact) => void;
  onSelectStudent: (contact: FamilyContact) => void;
  handleShowInquirer: () => void;
  handleShowStudent: () => void;
  showContactFamilies: () => void;
  inquiryForm: UseFormReturn<InquiryFormData>;
  onCreateNewContact: (type: "inquirer" | "student") => void;
  isDetailsTab?: boolean;
  detailsTabHeader?: JSX.Element;
  readOnly?: boolean;
}

export const InquiryFormLeftPane: FC<InquiryFormLeftPaneProps> = ({
  onSelectInquirer,
  onSelectStudent,
  showContactFamilies,
  inquiryForm: {
    watch,
    setValue,
    register,
    control,
    formState: { errors }
  },
  onCreateNewContact,
  handleShowStudent,
  handleShowInquirer,
  isDetailsTab,
  detailsTabHeader,
  readOnly = false
}) => {
  const { activeCenterId } = useAccessControl();
  const { activeConstants } = useCenterConstants(activeCenterId);
  const {
    activeServices,
    chainableFieldsDict: { servicesDict, subServicesDict } = { servicesDict: {}, subServicesDict: {} } as {
      servicesDict: ServiceDictionary;
      subServicesDict: SubServiceDictionary;
    }
  } = activeConstants;
  const { activeRelationships, STUDENT_VALUE } = useFamilyConstants();
  const inquiryConstantsQuery = useInquiryConstants();

  const selectedSubServiceWatch = useWatch({ control, name: "selectedSubService" });
  const [subjectOrTestOptions, subjectsLength, testsLength] = useMemo(() => {
    if (selectedSubServiceWatch) {
      const subServiceId = selectedSubServiceWatch?.split("::")[0];
      const subjects =
        subServicesDict?.[subServiceId]?.subjects.filter(subject =>
          activeConstants.activeSubjects.some(({ value }) => value === subject.value)
        ) ?? [];
      const tests =
        subServicesDict?.[subServiceId]?.tests.filter(test =>
          activeConstants.activeTests.some(({ value }) => value === test.value)
        ) ?? [];
      return [[...subjects, ...tests], subjects.length, tests.length];
    }
    setValue("selectedSubjectOrTest", "");
    return [[], 0, 0];
  }, [selectedSubServiceWatch]);

  const enableSubjectsOrTestsDropdown = Number(subjectsLength) || Number(testsLength);

  const serviceIdWatch = useWatch({ control, name: "serviceId" });
  const subServiceOptions = useMemo(() => {
    // Clear the selectedSubService value if selected sub service does not belong to the service
    const subServiceId = selectedSubServiceWatch?.split("::")[0];
    if (subServiceId && !servicesDict?.[serviceIdWatch]?.subServices.find(s => s.value === subServiceId)) {
      setValue("selectedSubService", "");
    }

    return (
      servicesDict?.[serviceIdWatch]?.subServices.filter(subService =>
        activeConstants.activePrograms.some(({ value }) => subService.value === value)
      ) ?? []
    );
  }, [serviceIdWatch]);

  const calculateDiscountedAeFee = () => {
    const { baseFee, discountValue, discountType } = watch("aeFee");
    const baseFeeNumber = typeof baseFee === "string" ? parseFloat(baseFee) : baseFee;

    let discountedFee = baseFeeNumber;
    switch (discountType) {
      case "$":
        discountedFee = baseFeeNumber - Number(discountValue);
        break;
      case "%":
        discountedFee = baseFeeNumber * (1 - Number(discountValue) / 100);
        break;
    }
    return discountedFee;
  };

  const discountType = watch("aeFee.discountType");

  useEffect(() => {
    const discountTypeId = inquiryConstantsQuery.data?.discountTypes
      ?.find(type => type.name === discountTypeCodes.get(discountType))
      ?.value?.toString();
    setValue("aeFee.discountTypeId", discountTypeId);
  }, [discountType]);

  const discountValue = Number(watch("aeFee.discountValue"));

  useEffect(() => {
    if (!discountValue) {
      setValue("aeFee.discountReasonId", "");
    }
  }, [discountValue]);

  const relationshipId = watch("relationshipId");
  const isStudentInquirer = Boolean(relationshipId) && relationshipId === STUDENT_VALUE;

  const student = watch("student");
  const nameHero = useMemo(() => {
    if (!student || !student.displayName) {
      return "New Student";
    } else {
      return student.displayName;
    }
  }, [student]);

  const subHeaderClass = classNames({ "card-subheader mb-0 mt-4": isDetailsTab });

  return (
    <CardBody
      className={classNames({
        "space-rows": !isDetailsTab
      })}
    >
      {!isDetailsTab && (
        <>
          <h2 className="mb-4">{nameHero}</h2>
          <Row>
            <Col md={6}>
              <ContactSearchController
                label="Initial Inquirer"
                onSelect={onSelectInquirer}
                addNewLabel="Create New Contact"
                onCreateNewClick={() => onCreateNewContact("inquirer")}
                name="inquirer"
                control={control}
                isRequired
              />
              <button
                className="mt-2 btn p-0 m-n text-left btn-link"
                onClick={handleShowInquirer}
                disabled={!watch("inquirer")?.id}
              >
                Initial Inquirer Details
              </button>
            </Col>
            <Col md={6}>
              <SelectField
                isRequired
                label="Relationship to Student"
                validationState={errors.relationshipId ? "error" : undefined}
                {...register("relationshipId")}
              >
                <option value="">Select Relationship</option>
                {activeRelationships
                  .sort(({ name: left }, { name: right }) => left.localeCompare(right))
                  .map(({ name, value }) => (
                    <option key={value} value={value}>
                      {name}
                    </option>
                  ))}
              </SelectField>
            </Col>
            <Col md={6}>
              <ContactSearchController
                label="Student"
                onSelect={onSelectStudent}
                addNewLabel="Create New Contact"
                onCreateNewClick={() => onCreateNewContact("student")}
                name="student"
                control={control}
                isRequired
                disabled={isStudentInquirer}
              />
              <button
                className="mt-2 btn p-0 m-n text-left btn-link"
                onClick={handleShowStudent}
                disabled={!watch("student")?.id}
              >
                Student Details
              </button>
            </Col>
            <Col md={6}>
              <div className="form-floating">
                <input
                  type="text"
                  id="family"
                  value={watch("family")?.name || ""}
                  className={classNames("form-control", {
                    "is-invalid": errors["family"]
                  })}
                  placeholder="Selected Family"
                  disabled
                  readOnly
                />
                <label htmlFor="family">
                  Selected Family{" "}
                  <span className="required" aria-hidden="true">
                    *
                  </span>
                </label>
                <button
                  className="mt-2 btn p-0 m-n text-left btn-link"
                  onClick={showContactFamilies}
                  disabled={!watch("inquirer")?.id || !watch("student")?.id}
                >
                  Change Family
                </button>
              </div>
            </Col>
          </Row>
        </>
      )}
      <Row>
        {detailsTabHeader}
        <Col md={4}>
          <SelectField
            disableColClass
            label="Service"
            validationState={errors["serviceId"] ? "error" : undefined}
            isRequired
            disabled={readOnly}
            {...register("serviceId")}
          >
            <option value="">Select Service</option>
            {(activeServices || [])
              .filter(({ isActiveForCenter }) => isActiveForCenter)
              .map(({ value, name }) => (
                <option key={value} value={value}>
                  {name}
                </option>
              ))}
          </SelectField>
        </Col>
        <Col md={4}>
          <SelectField
            disableColClass
            label="Sub-Service"
            validationState={errors["selectedSubService"] ? "error" : undefined}
            isRequired
            disabled={readOnly}
            {...register("selectedSubService")}
          >
            <option value="">Select Sub-Service</option>
            {(subServiceOptions ?? []).map(({ value, name }) => {
              const subjectsLength = subServicesDict?.[value]?.subjects?.length ?? 0;
              const testsLength = subServicesDict?.[value]?.tests?.length ?? 0;
              return (
                <option key={value} value={`${value}::${subjectsLength + testsLength}`}>
                  {name}
                </option>
              );
            })}
          </SelectField>
        </Col>
        <Col md={4}>
          <SelectField
            disableColClass
            label="Subject / Test"
            validationState={errors["selectedSubjectOrTest"] ? "error" : undefined}
            disabled={readOnly || !enableSubjectsOrTestsDropdown}
            isRequired={!!enableSubjectsOrTestsDropdown}
            {...register("selectedSubjectOrTest")}
          >
            <option value="">Select Subject / Test</option>
            {subjectOrTestOptions.map(({ value, name }, index) => (
              <option key={value} value={`${value}::${index >= subjectsLength ? "testId" : "subjectId"}`}>
                {name}
              </option>
            ))}
          </SelectField>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <h3 className={subHeaderClass}>Notes</h3>
        </Col>
        <Col md={12}>
          <div className="form-floating flex-grow-1">
            <textarea
              className="form-control"
              id="inquiry-form-notes"
              style={{ height: "100%", minHeight: "200px", overflow: "hidden", overflowY: "auto" }}
              disabled={readOnly}
              {...register("notes")}
            />
            <label htmlFor="inquiry-form-notes">Notes</label>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <h3 className={subHeaderClass}>Objections</h3>
        </Col>
        <Col md={12}>
          <CheckboxFieldset disabled={readOnly}>
            {inquiryConstantsQuery.data?.activeObjections.map(({ name, value }) => (
              <CheckboxField key={value} label={name} value={value} {...register("objections")} />
            ))}
          </CheckboxFieldset>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <h3 className={subHeaderClass}>AE Fee</h3>
        </Col>
        <Col md={6}>
          <InputField
            disableColClass
            type="number"
            label="Center AE Fee"
            step=".01"
            labelClassName="text-nowrap single-line-label mx-5 px-sm-2 bg-transparent"
            addOnClassName="fas icon fa-dollar-sign"
            validationState={errors.aeFee?.baseFee ? "error" : undefined}
            disabled={readOnly}
            {...register("aeFee.baseFee")}
          />
        </Col>
        <Col md={6}>
          <div className="d-flex">
            <div className="me-3">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  value="$"
                  id=":r90:"
                  disabled={readOnly}
                  {...register("aeFee.discountType")}
                />
                <label className="form-check-label" htmlFor=":r90:">
                  $
                </label>
              </div>
              <div className="form-check flex-grow-1">
                <input
                  className="form-check-input"
                  type="radio"
                  value="%"
                  id=":r91:"
                  disabled={readOnly}
                  {...register("aeFee.discountType")}
                />
                <label className="form-check-label" htmlFor=":r91:">
                  %
                </label>
              </div>
            </div>
            <div className="w-100">
              <InputField
                disableColClass
                type="number"
                label={`Discount`}
                step=".01"
                validationState={errors.aeFee?.discountValue ? "error" : undefined}
                disabled={readOnly}
                {...register("aeFee.discountValue")}
                labelClassName="text-nowrap single-line-label mx-5 px-sm-2 bg-transparent"
                addOnClassName={discountType === "$" ? "fas icon fa-dollar-sign" : "fas icon fa-percent"}
              />
            </div>
          </div>
        </Col>
        <Col md={6}>
          <InputField
            disableColClass
            type="number"
            label="Discounted AE Fee"
            value={(calculateDiscountedAeFee() ?? 0).toFixed(2)}
            disabled
            labelClassName="text-nowrap single-line-label mx-5 px-sm-2 bg-transparent"
            addOnClassName="fas icon fa-dollar-sign"
          />
        </Col>

        <Col md={6}>
          <SelectField
            label="Discount Reason"
            validationState={errors.aeFee?.discountReasonId ? "error" : undefined}
            disabled={readOnly || !discountValue}
            {...register("aeFee.discountReasonId")}
          >
            <option value="">Select Reason</option>
            {inquiryConstantsQuery.data?.activeDiscountReasons.map(({ name, value }) => (
              <option key={value} value={value}>
                {name}
              </option>
            )) ?? []}
          </SelectField>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <h3 className={subHeaderClass}>Hourly Tuition {isDetailsTab ? "QUOTED" : ""}</h3>
        </Col>
        <Col md={6}>
          <InputField
            disableColClass
            type="number"
            label="Standard Hourly"
            step=".01"
            validationState={errors.standardHourlyTuitionQuoted ? "error" : undefined}
            labelClassName="text-nowrap single-line-label mx-5 px-sm-2 bg-transparent"
            addOnClassName="fas icon fa-dollar-sign"
            disabled={readOnly}
            {...register("standardHourlyTuitionQuoted")}
          />
        </Col>
        <Col md={6}>
          <InputField
            disableColClass
            type="number"
            label="1-1 Hourly"
            step=".01"
            validationState={errors.privateHourlyTuitionQuoted ? "error" : undefined}
            labelClassName="text-nowrap single-line-label mx-5 px-sm-2 bg-transparent"
            addOnClassName="fas icon fa-dollar-sign"
            disabled={readOnly}
            {...register("privateHourlyTuitionQuoted")}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <h3 className={subHeaderClass}>Callback Special Quoted</h3>
        </Col>
        <Col md={6}>
          <InputField
            disableColClass
            type="number"
            label="Callback Special"
            step=".01"
            validationState={errors.callbackSpecialQuoted ? "error" : undefined}
            labelClassName="text-nowrap single-line-label mx-5 px-sm-2 bg-transparent"
            addOnClassName="fas icon fa-dollar-sign"
            disabled={readOnly}
            {...register("callbackSpecialQuoted")}
          />
        </Col>
      </Row>
    </CardBody>
  );
};
