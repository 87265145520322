import { useCallback, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useAccessControl } from "../../app/use-access-control";
import { callLcosService } from "../../data/lcosServices";
import { useAccessToken } from "../use-access-token";
import {
  InquiryListOptions,
  Inquiry,
  GetAllInquiriesResponsePayload,
  inquiryStatuses,
  InquiryStatus,
  InquiryListRequestParams
} from ".";
import { inquiryQueryKeys } from "./query-keys";

export const useInquiryList = (
  { search = "", statuses = [], sortBy = "", limit = 10, pageIndex = 0 }: InquiryListOptions = {},
  enabled = true
) => {
  const { getToken } = useAccessToken();
  const { activeCenterId } = useAccessControl();
  const [filteredCountPlaceholder, setFilteredCountPlaceholder] = useState(0);
  const offset = pageIndex * limit;
  const [previousInquiries, setPreviousInquiries] = useState<Inquiry[]>([]);

  const inquiryQuery = useQuery({
    queryKey: inquiryQueryKeys.list({ centerId: activeCenterId, search, statuses, sortBy, limit, offset }),
    queryFn: async () =>
      getAllInquiries(await getToken(), { centerId: activeCenterId, search, statuses, sortBy, limit, offset }),
    placeholderData: {
      inquiries: previousInquiries,
      filteredCount: filteredCountPlaceholder,
      statusesCount: Object.fromEntries(
        inquiryStatuses.map(status => [status, 0] as const)
      ) as GetAllInquiriesResponsePayload["statusesCount"]
    },
    select: useCallback(
      (data: GetAllInquiriesResponsePayload) => ({
        ...data,
        totalPages: Math.ceil(data.filteredCount / limit)
      }),
      [limit]
    ),
    enabled
  });

  useEffect(() => {
    if (inquiryQuery.data) {
      setPreviousInquiries(inquiryQuery.data.inquiries);
    }
  }, [inquiryQuery.data]);

  useEffect(() => {
    setFilteredCountPlaceholder(oldState => inquiryQuery.data?.filteredCount || oldState);
  }, [inquiryQuery.data?.filteredCount]);

  return inquiryQuery;
};

export const parseToInquiryStatus = (status: unknown): InquiryStatus | null =>
  inquiryStatuses.includes(status as InquiryStatus) ? (status as InquiryStatus) : null;

const getAllInquiries = async (
  accessToken: string,
  { centerId, search, statuses = [], sortBy, limit, offset }: InquiryListRequestParams
) =>
  callLcosService<GetAllInquiriesResponsePayload>(
    accessToken,
    `/api/inquiries/${centerId}?search=${search ?? ""}${statuses.map(status => `&status=${status}`).join("")}&sort_by=${
      sortBy ?? ""
    }&limit=${limit ?? ""}&offset=${offset}`,
    "GET"
  );
