import { useEffect, useMemo, useState, type FC } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import moment from "moment";
import invariant from "tiny-invariant";

import {
  type InquiryAppointmentStaffMember,
  type StudentInfo,
  type InquiryAppointment,
  useInquiryAppointmentAvailableStaff,
  useInquiryConstants,
  anyStaffMember
} from "../../../../hooks/use-inquiries";
import { useModal } from "../../../modal/modal";
import { useCenterConstants } from "../../../../hooks/use-center";
import { SimpleTable, SimpleTableHeader } from "../../../table";
import { StatusDropdown, useAppointmentCancelConfirmationModal } from "./status-dropdown";
import { FamilyContact, FamilyMemberInfo } from "../../../../hooks/use-families";
import { useAccessControl } from "../../../../app/use-access-control";
import { GenericMultiSelectCombobox } from "../../../search-combobox/generic-multi-select-combobox";
import { GenericMultiSelectDropdown } from "../../../search-combobox/generic-multi-select-dropdown";
import { LoadingOverlay } from "../../../loading-overlay/loading-overlay";

interface AppointmentSummarySharedProps {
  student?: StudentInfo;
  familyInfo?: FamilyMemberInfo[];
  appointment?: InquiryAppointment;
  contacts?: { value: number; label: string }[];
  onRescheduleAppointment: (appointment: InquiryAppointment) => void;
  onUpdateAppointmentStatus: (appointment: InquiryAppointment) => void;
}

interface AppointmentSummaryComponentProps extends AppointmentSummarySharedProps {
  updatedStaffList: InquiryAppointmentStaffMember[];
  updatedAttendeesList: FamilyContact[];
  onUpdateStaffList: (updatedList: InquiryAppointmentStaffMember[]) => void;
  onUpdateAttendeesList: (updatedList: FamilyContact[]) => void;
}

export const AppointmentSummary: FC<AppointmentSummaryComponentProps> = ({
  student,
  familyInfo,
  appointment,
  updatedStaffList,
  updatedAttendeesList,
  onRescheduleAppointment,
  onUpdateAppointmentStatus,
  onUpdateStaffList,
  onUpdateAttendeesList,
  contacts
}) => {
  const { activeCenterId } = useAccessControl();
  const centerConstantsQuery = useCenterConstants(activeCenterId);
  const inquiryConstantsQuery = useInquiryConstants();
  const { APPOINTMENT_STATUS_SCHEDULED_ID = "" } = inquiryConstantsQuery.data ?? {};

  invariant(appointment, "appointment is undefined");
  invariant(familyInfo, "familyInfo is undefined");
  invariant(student, "student is undefined");

  //const contacts = familyInfo.map(({ id, displayName }) => ({ id, displayName }));
  const { typeId, serviceId, locationId, scheduledDateTime } = appointment;

  const [activeStatusId, setActiveStatusId] = useState("");

  const scheduledDateTimeMoment = moment(scheduledDateTime);
  const appointmentTypeName = centerConstantsQuery.activeConstants.appointmentTypeLookup[typeId];
  const appointmentDuration =
    centerConstantsQuery.activeConstants.bookingsAppointmentDurations?.[serviceId]?.[typeId]?.[locationId] || 0;

  const inquiryAppointmentStaffQuery = useInquiryAppointmentAvailableStaff(activeCenterId, appointment.id);

  const availableStaffOptions = useMemo(
    () => [...(inquiryAppointmentStaffQuery.data ?? [])],
    [inquiryAppointmentStaffQuery.data]
  );

  const handleActiveStatusChange = (statusId: string) => {
    setActiveStatusId(statusId);
    onUpdateStaffList(appointment.staffMembers);
    onUpdateAttendeesList(appointment.attendees);
  };

  return (
    <LoadingOverlay
      loading={
        inquiryConstantsQuery.isLoading || centerConstantsQuery.isLoading || inquiryAppointmentStaffQuery.isLoading
      }
    >
      <Row>
        <Col>
          <div className="card shaded">
            <div className="card-header">
              <h3>
                {student?.displayName} {appointmentTypeName}
              </h3>
            </div>
            <div className="card-body">
              <Row>
                <Col className="gap-2">
                  <StatusDropdown
                    id={`appointmentSummaryStatusDropdown${appointment.id}`}
                    appointment={appointment}
                    onUpdateAppointmentStatus={onUpdateAppointmentStatus}
                    onRescheduleAppointment={onRescheduleAppointment}
                    dropdownClassName="mb-4"
                    menuClassName="w-100"
                    hideConfirmation
                    onActiveStatusChange={handleActiveStatusChange}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="gap-2">
                  Service:{" "}
                  <strong>
                    {centerConstantsQuery.activeConstants.services.find(service => service.value === serviceId)?.name}
                  </strong>
                </Col>
                <Col className="gap-2">
                  Location:{" "}
                  <strong>
                    {
                      centerConstantsQuery.activeConstants.appointmentLocations.find(
                        location => location.value === locationId
                      )?.name
                    }
                  </strong>
                </Col>
              </Row>
              <Row>
                <Col className="gap-2">
                  Date: <strong>{scheduledDateTimeMoment.format("M/D/yyyy")}</strong>
                </Col>
                <Col className="gap-2">
                  Time: <strong>{scheduledDateTimeMoment.format("h:mm a")}</strong>
                </Col>
              </Row>
              <Row>
                <Col className="gap-2">
                  Duration: <strong>{appointmentDuration} minutes</strong>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col md="6" className="col-12">
          {activeStatusId === APPOINTMENT_STATUS_SCHEDULED_ID && (
            <GenericMultiSelectCombobox
              items={availableStaffOptions}
              getLabel={item => item?.displayName ?? ""}
              getValue={item => item?.id ?? ""}
              onChange={onUpdateStaffList}
              toggleLabel="Staff"
              containerClassName="mb-3"
              selectedItems={updatedStaffList}
            />
          )}
          {/* the following div is not in the design but necessary to force th table styles */}
          <div className="card">
            <StaffMembersTable staff={updatedStaffList} />
          </div>
        </Col>
        <Col md="6" className="col-12">
          {activeStatusId === APPOINTMENT_STATUS_SCHEDULED_ID && (
            <GenericMultiSelectDropdown
              items={contacts?.map(c => ({ id: c.value, displayName: c.label })) ?? []}
              getLabel={item => item?.displayName ?? ""}
              getValue={item => item?.id.toString() ?? ""}
              onChange={onUpdateAttendeesList}
              toggleLabel="Attendees"
              containerClassName="mb-3"
              selectedItems={updatedAttendeesList}
            />
          )}
          {/* the following div is not in the design but necessary to force th table styles */}

          <div className="card">
            <AttendeesTable attendees={updatedAttendeesList} familyInfo={familyInfo} />
          </div>
        </Col>
      </Row>
    </LoadingOverlay>
  );
};

interface StaffMembersTableProps {
  staff: InquiryAppointmentStaffMember[];
}

const StaffMembersTable: FC<StaffMembersTableProps> = ({ staff }) => {
  const headers: SimpleTableHeader<InquiryAppointmentStaffMember>[] = [
    {
      name: "Staff",
      label: "",
      key: "displayName"
    }
  ];
  return <SimpleTable headers={headers} data={staff} uniqueIdKey="id" />;
};

interface AttendeesTableProps {
  attendees: FamilyContact[];
  familyInfo: FamilyMemberInfo[];
}

const AttendeesTable: FC<AttendeesTableProps> = ({ attendees, familyInfo }) => {
  const headers: SimpleTableHeader<FamilyContact>[] = [
    {
      name: "Attendee",
      label: "",
      key: "displayName"
    },
    {
      name: "Relationship to student",
      label: "",
      renderKey: "relationship",
      render: item => {
        // may need to filter and parse here
        const familyMember = familyInfo.find(familyMember => familyMember.id === item.id);
        if (familyMember?.subjectRelationshipNames.length) {
          return familyMember?.subjectRelationshipNames.join(", ");
        }
        // some extra added logic since relationships dont HAVE to exist in order for a member to be in the family
        return familyMember?.isPrimaryContact ? "Primary Contact" : "";
      }
    }
  ];

  return <SimpleTable headers={headers} data={attendees} uniqueIdKey="id" />;
};

export const useAppointmentSummaryModal = ({
  onRescheduleAppointment,
  onUpdateAppointmentStatus,
  ...props
}: AppointmentSummarySharedProps) => {
  const inquiryConstantsQuery = useInquiryConstants();
  const { hasFeatureAccess } = useAccessControl();
  const {
    APPOINTMENT_STATUS_SCHEDULED_ID = "",
    APPOINTMENT_STATUS_RESCHEDULED_ID = "",
    APPOINTMENT_STATUS_CANCELLED_ID = "",
    APPOINTMENT_STATUS_AS_SCHEDULED_ID = "",
    APPOINTMENT_STATUS_NO_SHOW_ID = ""
  } = inquiryConstantsQuery.data ?? {};
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const [updatePayload, setUpdatePayload] = useState<InquiryAppointment>(props.appointment!);
  useEffect(() => {
    if (props.appointment) {
      setUpdatePayload(props.appointment);
    }
  }, [props.appointment]);
  const [updatedStaffList, setUpdatedStaffList] = useState(props.appointment?.staffMembers ?? []);
  const [updatedAttendeesList, setUpdatedAttendeesList] = useState(props.appointment?.attendees ?? []);
  useEffect(() => {
    setUpdatedStaffList(props.appointment?.staffMembers ?? []);
    setUpdatedAttendeesList(props.appointment?.attendees ?? []);
  }, [props.appointment]);
  const { renderModal, hide, show } = useModal();
  const { renderCancelConfirmationModal, showCancelConfirmationModal } = useAppointmentCancelConfirmationModal({
    onConfirm: reasonToCancelId => {
      onUpdateAppointmentStatus({ ...updatePayload, reasonToCancelId });
      hide();
    }
  });
  const handleChangeStatus = (appointment: InquiryAppointment) => {
    setUpdatePayload(appointment);
  };
  const handleUpdateStaffList = (updatedList: InquiryAppointmentStaffMember[]) => {
    let staffList = [...updatedList];

    if (hasFeatureAccess?.("appointments_any_staff")) {
      if (updatedList.length === 0 || (updatedList.length > 1 && updatedList[updatedList.length - 1].id === "")) {
        staffList = [anyStaffMember];
      } else if (updatedList.length > 1 && updatedList[0].id === "") {
        staffList = updatedList.filter(staff => staff.id);
      }
    }

    setUpdatedStaffList(staffList);
  };

  const handleUpdateAttendeesList = (updatedList: { id: number; displayName: string }[]) => {
    setUpdatedAttendeesList(updatedList);
  };

  const handleSave = () => {
    const { statusId } = updatePayload;
    switch (statusId) {
      case APPOINTMENT_STATUS_AS_SCHEDULED_ID:
      case APPOINTMENT_STATUS_NO_SHOW_ID:
        hide();
        onUpdateAppointmentStatus(updatePayload);
        break;
      case APPOINTMENT_STATUS_CANCELLED_ID:
        showCancelConfirmationModal();
        break;
      case APPOINTMENT_STATUS_RESCHEDULED_ID:
        hide();
        onRescheduleAppointment(updatePayload);
        break;
      case APPOINTMENT_STATUS_SCHEDULED_ID:
      default:
        hide();
        onUpdateAppointmentStatus({
          ...updatePayload,
          statusId: APPOINTMENT_STATUS_SCHEDULED_ID,
          staffMembers: updatedStaffList,
          attendees: updatedAttendeesList
        });
        break;
    }
  };

  const checkSaveDisabled = () => {
    if (!updatePayload?.statusId || updatePayload.statusId === APPOINTMENT_STATUS_SCHEDULED_ID) {
      const sortedUpdatedAttendeesIds = updatedAttendeesList
        .map(attendee => attendee.id)
        .sort()
        .join(",");
      const sortedUpdatedStaffIds = updatedStaffList
        .map(staff => staff.id)
        .filter(staff => staff)
        .sort()
        .join(",");
      const sortedOriginalAttendeesIds = (props.appointment?.attendees || [])
        .map(attendee => attendee.id)
        .sort()
        .join(",");
      const sortedOriginalStaffIds = (props.appointment?.staffMembers || [])
        .map(staff => staff.id)
        .sort()
        .join(",");
      const listDirty =
        sortedUpdatedAttendeesIds &&
        (hasFeatureAccess?.("appointments_any_staff") || sortedUpdatedStaffIds) &&
        (sortedUpdatedAttendeesIds !== sortedOriginalAttendeesIds || sortedUpdatedStaffIds !== sortedOriginalStaffIds);
      return !listDirty;
    }
    return false;
  };

  const renderAppointmentSummaryModal = () => (
    <>
      {renderModal({
        size: "lg",
        title: "Appointment Summary",
        body: (
          <AppointmentSummary
            {...props}
            updatedStaffList={updatedStaffList}
            updatedAttendeesList={updatedAttendeesList}
            onRescheduleAppointment={handleChangeStatus}
            onUpdateAppointmentStatus={handleChangeStatus}
            onUpdateStaffList={handleUpdateStaffList}
            onUpdateAttendeesList={handleUpdateAttendeesList}
          />
        ),
        scrollable: false,
        footer: (
          <>
            <button className="btn btn-secondary" onClick={hide}>
              Cancel
            </button>
            <button className="btn btn-primary" disabled={checkSaveDisabled()} onClick={handleSave}>
              Save
            </button>
          </>
        )
      })}
      {renderCancelConfirmationModal()}
    </>
  );

  return {
    renderAppointmentSummaryModal,
    hideAppointmentSummaryModal: hide,
    showAppointmentSummaryModal: () => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      setUpdatePayload(props.appointment!);
      show();
    }
  };
};
