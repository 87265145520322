import { useId, type FC, useMemo } from "react";
// consider using pure bootstrap w/o downshift
import { useMultipleSelection, useSelect } from "downshift";
import classNames from "classnames";
import { FieldError } from "react-hook-form";
import { ComboboxItem } from "./search-select";

interface MultiSelectBaseProps {
  onChangeSelectedItems: (newValues: ComboboxItem[]) => void;
  options: ComboboxItem[];
  label: string;
  error?: FieldError;
  isRequired?: boolean;
}

interface MultiSelectWithSelectedItemsProps extends MultiSelectBaseProps {
  selectedItems?: ComboboxItem[];
  selectedValues?: never;
}

interface MultiSelectWithSelectedValuesProps extends MultiSelectBaseProps {
  selectedItems?: never;
  selectedValues?: string[] | number[];
}

type MultiSelectProps = MultiSelectWithSelectedValuesProps | MultiSelectWithSelectedItemsProps;
export const MultiSelect: FC<MultiSelectProps> = ({
  selectedItems,
  selectedValues,
  onChangeSelectedItems,
  options,
  label,
  error,
  isRequired
}) => {
  const selectedItemsFromProps = useMemo(() => {
    if (selectedValues) {
      const newSelectedItems: ComboboxItem[] = [];

      selectedValues?.forEach(value => {
        const option = options?.find(item => item.value === value);
        if (option) {
          newSelectedItems.push(option);
        }
      });
      return newSelectedItems;
    }

    if (selectedItems) {
      return selectedItems;
    }

    return [];
  }, [selectedItems, selectedValues]);

  const { getDropdownProps, selectedItems: selectedItemsForSelect } = useMultipleSelection({
    selectedItems: selectedItemsFromProps
  });
  const { isOpen, getToggleButtonProps, getLabelProps, getMenuProps, getItemProps } = useSelect({
    selectedItem: null,
    items: options,
    stateReducer: (_state, { changes, type }) => {
      switch (type) {
        case useSelect.stateChangeTypes.ToggleButtonKeyDownEnter:
        case useSelect.stateChangeTypes.ToggleButtonKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: true, // keep the menu open after selection.
            highlightedIndex: options.findIndex(({ value }) => value === changes.selectedItem?.value)
          };
      }
      return changes;
    },
    onStateChange: ({ type, selectedItem: newSelectedItem }) => {
      switch (type) {
        case useSelect.stateChangeTypes.ToggleButtonKeyDownEnter:
        case useSelect.stateChangeTypes.ToggleButtonKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:
        case useSelect.stateChangeTypes.ToggleButtonBlur:
          if (newSelectedItem) {
            if (selectedItemsForSelect.find(item => item.value === newSelectedItem.value)) {
              onChangeSelectedItems(selectedItemsForSelect.filter(item => item.value !== newSelectedItem.value));
            } else {
              onChangeSelectedItems([...(selectedItemsForSelect || []), newSelectedItem]);
            }
          }
          break;
        default:
          break;
      }
    }
  });

  const id = useId();

  return (
    <div className="dropdown form-input">
      <div className="form-floating">
        <div
          {...getToggleButtonProps(
            getDropdownProps({
              preventKeyAction: isOpen,
              className: classNames("form-select dropdown-toggle", { "is-invalid": error })
            })
          )}
        >
          {selectedItemsForSelect.map(item => options.find(({ value }) => value === item.value)?.label).join(", ")}
        </div>
        <label {...getLabelProps({ htmlFor: id })}>
          {label}
          {isRequired && (
            <>
              {" "}
              <span className="required" aria-hidden="true">
                *
              </span>
            </>
          )}
        </label>
        {!!error?.message && <div className="invalid-feedback">{error?.message}</div>}
      </div>
      <ul
        {...getMenuProps({
          className: classNames("dropdown-menu", { show: isOpen }),
          style: {
            postion: "absolute",
            inset: "0px auto auto 0px",
            margin: "0px",
            transform: "translate(136px, 60px)"
          }
        })}
      >
        {options.map((option, index) => (
          <li key={option.value} {...getItemProps({ item: option, index })}>
            <div className="form-check">
              <input
                className="form-check-input"
                readOnly
                type="checkbox"
                checked={!!selectedItemsForSelect.find(item => item.value === option.value)}
                id={`check-input-${option.value}`}
              />
              <label className="form-check=label" htmlFor={`check-input-${option.value}`}>
                {option.label}
              </label>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
