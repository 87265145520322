import { type FC } from "react";
import { useAccessControl } from "../../../../app/use-access-control";
import { useCenterConstants } from "../../../../hooks/use-center";
import moment from "moment";
import { InquiryAppointment } from "../../../../hooks/use-inquiries";

interface AppointmentDateTimeProps {
  appointment: InquiryAppointment;
}

export const AppointmentDateTime: FC<AppointmentDateTimeProps> = ({ appointment }) => {
  const { activeCenterId } = useAccessControl();
  const {
    activeConstants: { bookingsAppointmentDurations }
  } = useCenterConstants(activeCenterId);
  const { typeId, serviceId, locationId, scheduledDateTime } = appointment;
  const dateMoment = moment(scheduledDateTime);
  const date = dateMoment.format("M/DD/YYYY");
  const dayOfWeek = dateMoment.format("dddd");
  const startTime = dateMoment.format("h:mm a");
  const duration = bookingsAppointmentDurations?.[serviceId]?.[typeId]?.[locationId] || 0;
  const endTime = dateMoment.add(duration, "minute").format("h:mm a");
  return (
    <>
      {date} ({dayOfWeek})<br />
      {startTime} - {endTime}
    </>
  );
};
