import moment from "moment";
import { useMemo, useRef, useState, type FC } from "react";
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Row } from "react-bootstrap";

import { useAccessControl } from "../../../../app/use-access-control";
import { useAppointmentFormModal } from "../../../../forms/appointment/appointment-form";
import { usePhoneActivityModal } from "../../../../forms/phone-call-activity/phone-call-activity-form";
import { useInquiryDashboard, type Inquiry } from "../../../../hooks/use-inquiries";
import { useDeletePhoneActivity } from "../../../../hooks/use-inquiries/use-delete-phone-activity";
import { useTimeline, type TimelineEvent } from "../../../../hooks/use-inquiries/use-timeline";
import { IconButton } from "../../../buttons/icon-button";
import { Confirm, type ConfirmHandle } from "../../../confirm/confirm";
import { LoadingOverlay } from "../../../loading-overlay/loading-overlay";
import { SimpleTable, type SimpleTableHeader } from "../../../table";

export const TimelineInfo: FC<{ inquiry: Inquiry }> = ({ inquiry }) => {
  const { activeCenterId } = useAccessControl();
  const dashboardQuery = useInquiryDashboard({
    centerId: activeCenterId,
    inquiryId: inquiry.id
  });

  const timelineQuery = useTimeline(inquiry.id);
  const deletePhoneActivityMutation = useDeletePhoneActivity(inquiry.id);

  const [editingPhoneActivity, setEditingPhoneActivity] = useState<TimelineEvent | null>(null);
  const { showPhoneActivityModal, renderPhoneActivityModal } = usePhoneActivityModal({
    phoneActivity: editingPhoneActivity,
    onHideModal: () => {
      setEditingPhoneActivity(null);
    },
    inquiryId: inquiry.id,
    contacts: dashboardQuery.data?.contacts || []
  });

  const { showAppointmentFormModal, renderAppointmentFormModal } = useAppointmentFormModal({
    inquiryId: inquiry.id,
    centerId: activeCenterId,
    attendees: dashboardQuery.data?.attendees ?? []
  });

  const confirmDeletePhoneActivityModal = useRef<ConfirmHandle>(null);

  const onClickActionButton = (type: "create-phone-activity" | "new-appointment") => () => {
    if (type === "create-phone-activity") {
      showPhoneActivityModal();
    } else {
      showAppointmentFormModal();
    }
  };

  const tableHeaders: SimpleTableHeader<TimelineEvent>[] = useMemo(
    () => [
      {
        renderKey: "dateTime",
        name: "Date / Time",
        render: ({ startDateTime }) => (
          <div>
            {moment(startDateTime).format("M/D/YYYY")}
            <br />
            {moment(startDateTime).format("h:mma")}
          </div>
        ),
        tableHeaderCellProps: { className: "no-wrap" },
        tableDataCellProps: { className: "w-auto no-wrap" }
      },
      {
        renderKey: "description",
        name: "Description",
        render: timelineEvent => (
          <Accordion className="undecorated">
            <AccordionItem eventKey="0">
              <AccordionHeader className="d-flex">
                <div className="w-100 d-flex justify-content-between align-items-center">
                  <div className="table-accordion-header-content">
                    <strong>Contact Type: {timelineEvent.eventMedium}</strong>
                    <div className="secondary">
                      <strong>Attendee(s):</strong>
                      {` ${timelineEvent.attendees.map(formatAttendee).join(", ")}`}
                      <br />
                      <strong>Staff:</strong>
                      {` ${timelineEvent.staffMembers.map(({ firstName, lastName }) => `${firstName} ${lastName}`).join(", ")}`}
                    </div>
                  </div>
                  {timelineEvent.canEdit && (
                    <div className="d-flex m-2">
                      {/* TODO: Revisit these styles when the design includes the buttons */}
                      <IconButton
                        icon="edit"
                        title="Edit"
                        className="btn btn-table"
                        enableTooltip
                        onClick={e => {
                          e.stopPropagation();
                          setEditingPhoneActivity(timelineEvent);
                          showPhoneActivityModal();
                        }}
                      />
                      <IconButton
                        icon="trash"
                        title="Delete"
                        className="btn btn-table"
                        enableTooltip
                        onClick={e => {
                          e.stopPropagation();
                          confirmDeletePhoneActivityModal.current?.confirm(() => {
                            deletePhoneActivityMutation.mutate(timelineEvent.id);
                          });
                        }}
                      />
                    </div>
                  )}
                </div>
              </AccordionHeader>
              <AccordionBody>{timelineEvent.notes}</AccordionBody>
            </AccordionItem>
          </Accordion>
        ),
        tableDataCellProps: { className: "w-100" }
      }
    ],
    []
  );

  return (
    <LoadingOverlay loading={timelineQuery.isLoading}>
      <div className="card-body">
        {renderPhoneActivityModal()}
        {renderAppointmentFormModal()}
        <Confirm
          ref={confirmDeletePhoneActivityModal}
          title="Confirm Delete"
          message="Are you sure you want to delete the entry?"
          okLabel="Yes"
          cancelLabel="No"
        />
        <Row>
          <div className="d-flex mb-4 justify-content-between align-items-center">
            <h3 className="card-subheader mb-0">Time-Line</h3>
            <div className="d-flex">
              <div className="btn-group">
                <button
                  type="button"
                  className="btn btn-primary btn-sm ms-2 dropdown-toggle dropdown-toggle-split"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span className="m-2">Create Activity</span>
                  <span className="visually-hidden">Toggle Dropdown</span>
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <button
                      className="dropdown-item"
                      type="button"
                      onClick={onClickActionButton("create-phone-activity")}
                    >
                      Phone Call
                    </button>
                  </li>
                  <li>
                    <button className="dropdown-item" type="button" onClick={onClickActionButton("new-appointment")}>
                      New Appointment
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Row>

        <SimpleTable headers={tableHeaders} data={timelineQuery.data} uniqueIdKey="id" className="vertical-top" />
      </div>
    </LoadingOverlay>
  );
};

const formatAttendee = ({
  firstName,
  lastName,
  relation
}: {
  firstName: string;
  lastName: string;
  relation?: string;
}) => `${firstName} ${lastName}${relation ? ` (${relation})` : ""}`;
