import classnames from "classnames";
import React, { useMemo } from "react";
import Accordion from "react-bootstrap/Accordion";

import { useAccessControl } from "../../app/use-access-control";
import { CODES } from "../../data/dynamics-codes";
import * as Permissions from "../../data/permissions";
import { useCenterConstants } from "../../hooks/use-center";
import { useStaffAppointmentTypes, type Availability } from "../../hooks/use-staff/use-staff-appointment-types";
import { SimpleTable, type SimpleTableHeader } from "../table/simple-table";
import { AppointmentLocationSelect } from "./appointment-location-select";

interface AppointmentTypesAccordionProps {
  staffMemberId: string;
}

export const AppointmentAvailabilityAccordion: React.FC<AppointmentTypesAccordionProps> = ({ staffMemberId }) => {
  const { activeCenterId, hasPermission, profile } = useAccessControl();
  const centerConstantsQuery = useCenterConstants(activeCenterId);

  const { appointmentTypes, updateAvailabilityForAppointmentTypeMutator } = useStaffAppointmentTypes(
    activeCenterId,
    staffMemberId
  );

  const isEditingSelf = profile?.id === staffMemberId;
  const hasScheduleEditPermission = hasPermission && hasPermission(Permissions.SCHEDULE_EDIT);
  const canEdit = !isEditingSelf && hasScheduleEditPermission;

  const locationOptions = useMemo(() => {
    return (
      centerConstantsQuery.activeConstants?.activeAppointmentLocations?.filter(
        ({ code }) => code === CODES.LOCATIONS.IN_CENTER || code === CODES.LOCATIONS.ONLINE_VIRTUAL
      ) ?? []
    );
  }, [centerConstantsQuery.activeConstants?.appointmentLocations]);

  const handleCheckboxChange = (appointmentTypeId: string, availability: Availability, newValue: boolean) => {
    updateAvailabilityForAppointmentTypeMutator.mutate({
      appointmentTypeId,
      updatedAvailability: {
        serviceId: availability.serviceId,
        isAvailable: newValue,
        locations: locationOptions.map(option => option.value as string)
      },
      skipQueryUpdate: false
    });
  };

  const createHeaders = (appointmentTypeId: string) => {
    const headers: SimpleTableHeader<Availability>[] = [
      {
        name: "Service",
        key: "name",
        label: "Service",
        tableDataCellProps: {
          isPrimary: true,
          className: "w-50"
        }
      },
      {
        name: "Available",
        renderKey: "isAvailable",
        label: "Allowed",
        render: item => (
          <div className={classnames("styled-check", { disabled: !canEdit })}>
            <label htmlFor={`available-checkbox-${appointmentTypeId}-${item.serviceId}`}>
              <span className="sr-only">{item.name}</span>
              <input
                className="form-check-input"
                type="checkbox"
                name={`available-checkbox-${appointmentTypeId}-${item.serviceId}`}
                id={`available-checkbox-${appointmentTypeId}-${item.serviceId}`}
                data-testid={`available-checkbox-${appointmentTypeId}-${item.serviceId}`}
                checked={item.isAvailable}
                onChange={e => handleCheckboxChange(appointmentTypeId, item, e.target.checked)}
                disabled={!canEdit}
              ></input>
              <span className="icon"></span>
            </label>
          </div>
        ),
        tableDataCellProps: {
          className: "td-highlight",
          hasIcon: true
        },
        tableHeaderCellProps: {
          hasIcon: true
        }
      },
      {
        name: "Location",
        renderKey: "locationName",
        label: "",
        render: item =>
          item.isAvailable ? (
            canEdit ? (
              <AppointmentLocationSelect
                staffMemberId={staffMemberId}
                item={item}
                appointmentTypeId={appointmentTypeId}
              />
            ) : (
              item.locations.map(location => location.name).join(", ")
            )
          ) : null,
        tableDataCellProps: { className: "td-highlight w-50" }
      }
    ];
    return headers;
  };

  return (
    <Accordion defaultActiveKey="0">
      {appointmentTypes.map(appointmentType => (
        <Accordion.Item
          data-testid={`appt-avail-accordion-item-${appointmentType.appointmentTypeId}`}
          key={`appt-avail-accordion-item-${appointmentType.appointmentTypeId}`}
          eventKey={`appt-avail-accordion-item-${appointmentType.appointmentTypeId}`}
        >
          <Accordion.Header>{appointmentType.appointmentTypeName}</Accordion.Header>
          <Accordion.Body>
            <SimpleTable
              headers={createHeaders(appointmentType.appointmentTypeId)}
              data={appointmentType.availability}
              uniqueIdKey={"serviceId"}
              tableHover
              tableCollapseScreenSize="sm"
              tableHasIcons
            />
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};
