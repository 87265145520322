import { useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { useFamilyConstants, useFamilyMembers } from "../../hooks/use-families";
import {
  InquiryAppointment,
  useInquiryAppointments,
  useInquiryConstants,
  useInquiryDashboard,
  type InquiryFormData
} from "../../hooks/use-inquiries";
import { SelectField } from "../forms/select-field";
import { InputField } from "../forms/input-field";
import { SimpleTable, SimpleTableHeader } from "../table";
import { ContentPad, Utility } from "../../app/app-layout";
import { useMyProfile } from "../../hooks/use-my-profile";
import { useAppointmentFormModal } from "../../forms/appointment/appointment-form";
import { useAccessControl } from "../../app/use-access-control";
import { UpcomingEventsTable } from "../inquiry-list/tab-content/inquiry-dashboard";
import { useAppointmentSummaryModal } from "../inquiry-list/tab-content/inquiry-dashboard/appointment-summary";
import { AppointmentHistoryAccordion } from "../inquiry-list/tab-content/inquiry-dashboard/appointment-history-accordion";

export interface InquiryRightSideAccordionProps {
  familyId?: string | null;
  onClose: () => void;
}

export interface RelationshipsItem {
  subjectContactId: number;
  subjectDisplayName?: string | undefined;
  relationship: string;
}

export const InquiryRightSideAccordion: React.FC<InquiryRightSideAccordionProps> = ({ familyId, onClose }) => {
  const { profile } = useMyProfile();
  const inquiryConstantsQuery = useInquiryConstants();
  const { familyRelationshipLookup } = useFamilyConstants();
  const { data: allFamilyMembers } = useFamilyMembers(familyId);
  const { watch, register, formState } = useFormContext<InquiryFormData>();
  const { activeCenterId } = useAccessControl();

  const student = watch("student");
  const inquirer = watch("inquirer");
  const relationshipId = watch("relationshipId");
  const inquiryId = watch("id");

  const relationships: RelationshipsItem[] = useMemo(() => {
    const results: RelationshipsItem[] = [];

    if (inquirer) {
      const inquirerItem: RelationshipsItem = {
        subjectDisplayName: inquirer.displayName,
        subjectContactId: inquirer.id,
        relationship: familyRelationshipLookup[relationshipId ?? "notapplicable"]
      };
      results.push(inquirerItem);
    }

    const sorted =
      allFamilyMembers?.members?.slice?.()?.sort((left, right) => left.displayName.localeCompare(right.displayName)) ??
      [];

    sorted.forEach(member => {
      if (!results.find(r => r.subjectContactId === member.id) && student?.id !== member.id) {
        const extraContactItem: RelationshipsItem = {
          subjectDisplayName: member.displayName,
          subjectContactId: member.id,
          relationship: ""
        };
        results.push(extraContactItem);
      }
    });

    return results;
  }, [inquirer, relationshipId, familyRelationshipLookup, allFamilyMembers]);

  const familyDetailHeaders: SimpleTableHeader<RelationshipsItem>[] = [
    {
      key: "subjectDisplayName",
      name: "Name"
    },
    {
      key: "relationship",
      name: "Relationship"
    }
  ];

  const [selectedUpcomingAppointment, setSelectedUpcomingAppointment] = useState<InquiryAppointment>();
  const [activeAppointment, setActiveAppointment] = useState<InquiryAppointment>();

  const dashboardQuery = useInquiryDashboard({
    centerId: activeCenterId,
    inquiryId: inquiryId
  });

  const { updateEventMutator } = useInquiryAppointments({
    centerId: activeCenterId,
    inquiryId: inquiryId ?? ""
  });

  const { showAppointmentFormModal, renderAppointmentFormModal } = useAppointmentFormModal({
    inquiryId: inquiryId ?? "",
    appointment: activeAppointment,
    centerId: activeCenterId,
    attendees: dashboardQuery.data?.attendees ?? []
  });

  const handleUpdateAppointmentStatus = (appointment: InquiryAppointment) => {
    updateEventMutator.mutate({
      ...appointment,
      staffMembers: appointment.staffMembers.map(({ id }) => id).filter(id => id),
      attendees: appointment.attendees.map(({ id }) => id)
    });
  };

  const handleRescheduleAppointment = (appointment: InquiryAppointment) => {
    setActiveAppointment(appointment);
    showAppointmentFormModal();
  };

  const handleCreateNewAppointment = () => {
    setActiveAppointment(undefined);
    showAppointmentFormModal();
  };

  const handleClickUpcomingAppointment = (appointment: InquiryAppointment) => {
    setSelectedUpcomingAppointment(appointment);
    showAppointmentSummaryModal();
  };

  const { showAppointmentSummaryModal, renderAppointmentSummaryModal } = useAppointmentSummaryModal({
    student: dashboardQuery.data?.student,
    familyInfo: dashboardQuery.data?.familyInfo,
    contacts: dashboardQuery.data?.attendees,
    appointment: selectedUpcomingAppointment,
    onUpdateAppointmentStatus: handleUpdateAppointmentStatus,
    onRescheduleAppointment: handleRescheduleAppointment
  });

  const referredById = watch("referredById");
  const referredByDescriptionLabel = useMemo(() => {
    const selection = inquiryConstantsQuery.data?.activeReferralSources?.find(afs => afs.value === referredById);
    if (selection) {
      return `Referred by ${selection.name}`;
    } else {
      return "";
    }
  }, [referredById, inquiryConstantsQuery.data?.activeReferralSources]);

  return (
    <>
      <div className="sticky-top d-lg-none">
        <Utility>
          <div className="d-flex stack-sm-down w-100 justify-content-end">
            <button className="btn btn-secondary me-2" onClick={onClose}>
              Exit
            </button>
          </div>
        </Utility>
      </div>
      <ContentPad>
        <h2 className="header-small">Details</h2>

        {/* this uses the OG accordion CSS classes because the react-bootstrap components refuse to 
        cooperate with the secondary button in the appointments header */}
        <div className="accordion">
          <div className="accordion-item">
            <h3 className="accordion-header" id="initialContactHeader">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#initialContactForm"
                aria-expanded="true"
                aria-controls="collapseLayout"
              >
                Initial Contact
              </button>
            </h3>
            <div
              id="initialContactForm"
              className="accordion-collapse collapse show"
              aria-labelledby="initialContactHeader"
            >
              <div className="accordion-body">
                <div className="card mb-0">
                  <div className="card-body pt-0">
                    <Row>
                      <Col md={12} lg={6}>
                        <InputField disableColClass type="date" label="Inquiry Date" {...register("inquiryDate")} />
                      </Col>

                      <Col md={12} lg={6}>
                        <InputField
                          disableColClass
                          type="time"
                          label="Time Received"
                          {...register("inquiryTimeReceived")}
                        />
                      </Col>
                      <Col md={12} lg={6}>
                        {/* TODO: update with the appropriate drop down logic */}
                        <SelectField disableColClass label="Taken By" {...register("takenById")}>
                          <option value={profile?.id}>{profile?.displayName}</option>
                        </SelectField>
                      </Col>
                      <Col md={12} lg={6}>
                        <InputField
                          disableColClass
                          type="number"
                          label="Duration"
                          {...register("durationMinutes")}
                          error={formState?.errors["durationMinutes"]}
                        />
                      </Col>

                      <Col md={12}>
                        <SelectField
                          disableColClass
                          label="Contact Method"
                          defaultValue=""
                          isRequired
                          validationState={formState?.errors.sourceId ? "error" : undefined}
                          {...register("sourceId")}
                        >
                          <option disabled value="" />
                          {inquiryConstantsQuery.data?.contactMethodSources.map(({ name, value }) => (
                            <option key={value} value={value}>
                              {name}
                            </option>
                          ))}
                        </SelectField>
                      </Col>

                      <Col md={12}>
                        <SelectField
                          data-testid="inquiry-referred-by-dropdown"
                          disableColClass
                          label="Referred By"
                          defaultValue=""
                          isRequired
                          validationState={formState?.errors.referredById ? "error" : undefined}
                          {...register("referredById")}
                        >
                          <option disabled value="" />
                          {inquiryConstantsQuery.data?.activeReferralSources.map(({ name, value }) => (
                            <option key={value} value={value}>
                              {name}
                            </option>
                          ))}
                        </SelectField>
                      </Col>

                      {!!referredByDescriptionLabel && (
                        <Col md={12}>
                          <InputField
                            data-testid="inquiry-referred-by-description"
                            type="text"
                            label={referredByDescriptionLabel}
                            {...register("referredByOther")}
                          />
                        </Col>
                      )}
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h3 className="accordion-header" id="contactRelationshipsHeader">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#contactRelationships"
                aria-expanded="true"
                aria-controls="collapseLayout"
              >
                Family Details
              </button>
            </h3>
            <div
              id="contactRelationships"
              className="accordion-collapse collapse show"
              aria-labelledby="contactRelationshipsHeader"
            >
              <div className="accordion-body">
                <SimpleTable
                  tableCollapseScreenSize="sm"
                  headers={familyDetailHeaders}
                  data={relationships}
                  uniqueIdKey="subjectContactId"
                />
              </div>
            </div>
          </div>
          <div className="accordion-item  has-secondary-header-button">
            <h3 className="accordion-header" id="upcomingAppointmentsHeader">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#upcomingAppointments"
                aria-expanded="true"
                aria-controls="collapseLayout"
              >
                Upcoming Appointments
              </button>
              <OverlayTrigger
                trigger={!familyId || !inquiryId ? ["focus", "hover", "click"] : []}
                placement={"top"}
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip>inquiry must be saved before appointments can be added</Tooltip>}
              >
                <button
                  className="btn btn-primary secondary-header-button"
                  onClick={handleCreateNewAppointment}
                  disabled={!familyId || !inquiryId}
                >
                  Create Appointment
                </button>
              </OverlayTrigger>
            </h3>
            <div
              id="upcomingAppointments"
              className="accordion-collapse collapse show p-3"
              aria-labelledby="upcomingAppointmentsHeader"
            >
              <UpcomingEventsTable
                upcomingEvents={dashboardQuery.data?.upcomingEvents}
                onUpdateAppointmentStatus={handleUpdateAppointmentStatus}
                onRescheduleAppointment={handleRescheduleAppointment}
                onClickUpcomingEvent={handleClickUpcomingAppointment}
              />
            </div>
          </div>
        </div>

        <AppointmentHistoryAccordion defaultActiveKey="0" appointmentHistory={dashboardQuery.data?.pastEvents} />

        {renderAppointmentFormModal()}
        {renderAppointmentSummaryModal()}
      </ContentPad>
    </>
  );
};
