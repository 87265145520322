import { zodResolver } from "@hookform/resolvers/zod";
import isEqual from "lodash/isEqual";
import moment from "moment";
import {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
  type FC,
  type ReactEventHandler,
  type Reducer
} from "react";
import { FormProvider, useFieldArray, useForm, type ErrorOption, type FieldErrors } from "react-hook-form";
import invariant from "tiny-invariant";
import { z } from "zod";
import { useMediaQuery } from "usehooks-ts";

import { useAccessControl } from "../../app/access-control-context";
import { ContentPad, ContentViewport, Leftside, Rightside, Utility } from "../../app/app-layout";
import { Confirm, ConfirmHandle } from "../../components/confirm/confirm";
import { InquiryFormLeftPane, inquirySchema } from "../../components/inquiry-form/inquiry-form-left-pane";
import {
  InquiryFormRightPane,
  type InquiryFormRightPaneModes
} from "../../components/inquiry-form/inquiry-form-right-pane";
import { NavTab, TabContainer, TabContent } from "../../components/nav-tabs/nav-tabs";
import { useCenterConstants } from "../../hooks/use-center";
import {
  useFamilyConstants,
  type Family,
  type FamilyContact,
  type FamilyContactDetailWithId,
  useFamilyContactDetail
} from "../../hooks/use-families";
import { defaultInquiryFormData, useCreateOrUpdateInquiry, type InquiryFormData } from "../../hooks/use-inquiries";
import { useInquirySaveAll } from "../../hooks/use-inquiries/use-inquiry-save-all";
import { useNavigationConfirmation } from "../../hooks/use-navigation-confirmation";
import { dispatchSidebarMenuTriggerEvent } from "../../app/app-menu";

const tabSchema = z.object({
  tabId: z.string()
});

const allInquiriesSchema = z.object({
  inquiries: z.array(tabSchema.and(inquirySchema))
});

type AllInquiriesType = z.infer<typeof allInquiriesSchema>;

export const InquiryFormScreen: FC = () => {
  const confirmCloseWithoutSavingModalMultipleTabs = useRef<ConfirmHandle>(null);
  const confirmCloseWithoutSavingModalSingleTab = useRef<ConfirmHandle>(null);

  const getDefaultValues = useDefaultValuesFactory();

  const initialTabId = useMemo(() => crypto.randomUUID(), []);
  const [tabState, dispatch] = useReducer(tabReducer, {
    activeTabId: initialTabId,
    tabs: [
      {
        id: initialTabId,
        formValues: useMemo(() => getDefaultValues(), []),
        cleanFormValues: useMemo(() => getDefaultValues(), []),
        isDirty: false
      }
    ]
  });

  const { activeCenterId } = useAccessControl();
  const {
    activeConstants: { services }
  } = useCenterConstants(activeCenterId);

  const activeTab = useMemo(() => tabState.tabs.find(tab => tab.id === tabState.activeTabId), [tabState.activeTabId]);
  const activeTabIndex = useMemo(
    () => tabState.tabs.findIndex(tab => tab.id === tabState.activeTabId),
    [tabState.activeTabId]
  );
  invariant(activeTab, `Tab with ID "${tabState.activeTabId}" not found in tabs.`);

  const inquiryForm = useForm({
    values: activeTab.cleanFormValues,
    mode: "onTouched",
    // reValidateMode: "onChange",
    resolver: zodResolver(inquirySchema)
  });

  const allInquiries = useForm({
    values: { inquiries: tabState.tabs.map(tab => ({ tabId: tab.id, ...tab.formValues })) },
    resolver: zodResolver(allInquiriesSchema),
    resetOptions: {
      keepErrors: true
    }
  });

  const createOrUpdateInquiryMutator = useCreateOrUpdateInquiry();

  const onSaveInquiry = (savedInquiry: InquiryFormData) => {
    window.toasts.add({ type: "success", content: "Saved inquiry successfully!" });
    inquiryForm.setValue("id", savedInquiry.id);
    inquiryForm.setValue("callbackSpecialQuoted", savedInquiry.callbackSpecialQuoted);
    inquiryForm.setValue("family", savedInquiry.family);
    inquiryForm.setValue("familyId", savedInquiry.family?.id);

    dispatch({
      type: "save_active_tab",
      payload: { savedInquiry }
    });
  };

  const handleSaveCurrentInquiry = inquiryForm.handleSubmit(formValues => {
    createOrUpdateInquiryMutator.mutate(
      { inquiryId: formValues.id, formData: formValues },
      { onSuccess: onSaveInquiry }
    );
  });

  const inquiryTabs = useFieldArray({ control: allInquiries.control, name: "inquiries" as never });

  useEffect(() => {
    // reset form when active tab changes
    inquiryForm.reset(activeTab.cleanFormValues);
    inquiryForm.reset(activeTab.formValues, { keepDefaultValues: true });
  }, [inquiryForm.reset, inquiryForm.setError, activeTab]);

  useEffect(() => {
    // set errors when active tab changes
    Object.entries(activeTab.validationErrors ?? {}).forEach(([field, error]) => {
      inquiryForm.setError(field as keyof InquiryFormData, error as ErrorOption);
    });
  }, [activeTab]);

  useEffect(() => {
    dispatch({
      type: "set_active_tab_validation_errors",
      payload: {
        errors: inquiryForm.formState.errors
      }
    });
  }, [inquiryForm.formState.errors]);

  useEffect(() => {
    dispatch({ type: "set_active_tab_dirty", payload: { isDirty: inquiryForm.formState.isDirty } });
  }, [inquiryForm.formState.isDirty]);

  useEffect(() => {
    dispatch({
      type: "set_all_validation_errors",
      payload: {
        errors: allInquiries.formState.errors
      }
    });
    Object.entries(allInquiries.formState.errors.inquiries?.[activeTabIndex] ?? {}).forEach(([field, error]) => {
      inquiryForm.setError(field as keyof InquiryFormData, error as ErrorOption);
    });
  }, [JSON.stringify(allInquiries.formState.errors)]);

  const isSmallScreen = useMediaQuery("(max-width: 991.98px)", { defaultValue: false });

  const [rightPaneMode, setRightPaneMode] = useState<InquiryFormRightPaneModes | undefined>(
    isSmallScreen ? undefined : "default"
  );
  useEffect(() => {
    if (!isSmallScreen && rightPaneMode === undefined) {
      setRightPaneMode("default");
    }
  }, [isSmallScreen, rightPaneMode]);

  const showDefaultRightPane = () => {
    setRightPaneMode("default");
  };

  const handleRightPaneClose = () => {
    setRightPaneMode(undefined);
  };

  const handleShowDetails = () => {
    setRightPaneMode("default");
  };

  const showContactFamilies = () => {
    setRightPaneMode("select-family");
  };

  const handleShowStudent = () => {
    setRightPaneMode("quick-create-student");
  };

  const handleShowInquirer = () => {
    setRightPaneMode("quick-create-inquirer");
  };

  const handleSelectContact = useCallback(
    (type: "inquirer" | "student", setValue = true) =>
      (contact: FamilyContact) => {
        if (setValue) {
          inquiryForm.setValue(type, contact, { shouldDirty: true, shouldValidate: true });
          allInquiries.setValue(`inquiries.${activeTabIndex}.${type}`, contact, {
            shouldDirty: true,
            shouldValidate: true
          });
        }
      },
    [inquiryForm.setValue, allInquiries.setValue, activeTabIndex]
  );

  const { STUDENT_VALUE } = useFamilyConstants();
  const [relationshipId, inquirer, student, family, serviceId] = inquiryForm.watch([
    "relationshipId",
    "inquirer",
    "student",
    "family",
    "serviceId"
  ]);
  useEffect(() => {
    if (inquirer && relationshipId && relationshipId === `${STUDENT_VALUE}`) {
      handleSelectContact("student")(inquirer);
    }
  }, [relationshipId, inquirer, STUDENT_VALUE, handleSelectContact]);

  const handleCreateNewContact = (type: "inquirer" | "student") => {
    const defaultFieldValue = getDefaultValues()[type];
    inquiryForm.setValue(type, defaultFieldValue, { shouldDirty: true, shouldValidate: true });
    allInquiries.setValue(`inquiries.${activeTabIndex}.${type}`, defaultFieldValue, {
      shouldDirty: true,
      shouldValidate: true
    });
    setRightPaneMode(type === "inquirer" ? "quick-create-inquirer" : "quick-create-student");
  };

  const handleSelectFamily = (family: Family) => {
    if (family.id === null) {
      showDefaultRightPane();
    }
    const familyValue = { ...family, name: family.name ?? "" };
    inquiryForm.setValue("family", familyValue, { shouldDirty: true, shouldValidate: true });
    allInquiries.setValue(`inquiries.${activeTabIndex}.family`, familyValue);
  };

  const leftPaneProps = {
    onSelectInquirer: handleSelectContact("inquirer"),
    onSelectStudent: handleSelectContact("student"),
    showContactFamilies,
    inquiryForm,
    onCreateNewContact: handleCreateNewContact,
    handleShowStudent,
    handleShowInquirer
  };

  const rightPaneProps = {
    mode: rightPaneMode,
    onSelectContact: (type: "inquirer" | "student", contact: FamilyContact) => handleSelectContact(type)(contact),
    onSelectFamily: handleSelectFamily,
    onClose: handleRightPaneClose,
    student,
    inquirer,
    family
  };

  const hasSelectedInquirer = Boolean(inquirer?.id);
  const hasSelectedStudent = Boolean(student?.id);
  const hasSelectedFamily = Boolean(family?.id);
  const shouldShowFamilyPane = hasSelectedInquirer && hasSelectedStudent && !hasSelectedFamily;
  useEffect(() => {
    setRightPaneMode(prevMode => {
      if (shouldShowFamilyPane) {
        return "select-family";
      } else if (prevMode === "select-family") {
        return "default";
      } else {
        return prevMode;
      }
    });
  }, [setRightPaneMode, shouldShowFamilyPane]);

  const getTabName = (student: FamilyContactDetailWithId | undefined, serviceId: number | "") => {
    const name = student?.displayName || `New Student`;

    const service = services.find(({ value }) => `${value}` === `${serviceId}`)?.name;
    const serviceSplit = service?.split(" ");
    const serviceAbbreviated =
      (serviceSplit?.length || 0) > 1
        ? serviceSplit?.reduce((response, word) => (response += word.slice(0, 1)), "")
        : service;

    return [name, serviceAbbreviated].filter(itm => itm !== undefined).join(" - ");
  };

  const { contactDetail: studentDetail } = useFamilyContactDetail("student", student?.id);

  const nameGradeHeader = useMemo(() => {
    if (!student?.displayName) {
      return <strong>New Student</strong>;
    }
    if (studentDetail?.grade) {
      return (
        <>
          <strong>{student.displayName}</strong> ({studentDetail.grade.name})
        </>
      );
    }
    return <strong>{student.displayName}</strong>;
  }, [studentDetail]);

  const tabNameMapper = useMemo(() => {
    const results: Record<UUID, string> = {};
    tabState.tabs.forEach(tab => {
      results[tab.id] =
        tab.id === tabState.activeTabId
          ? getTabName(student as FamilyContactDetailWithId, serviceId)
          : getTabName(tab.formValues.student as FamilyContactDetailWithId, tab.formValues.serviceId);
    });

    return results;
  }, [tabState.tabs.length, student, serviceId]);

  const inquiriesDropdownOptions = useMemo(() => {
    return tabState.tabs.map(tab => {
      return (
        <option key={tab.id} value={tab.id} selected={tab.id === tabState.activeTabId}>
          {tabNameMapper[tab.id]}
        </option>
      );
    });
  }, [tabNameMapper]);

  const { blocker } = useNavigationConfirmation({ confirmNavigation: !!tabState.tabs.find(tab => tab.isDirty) });

  useEffect(() => {
    if (blocker.state === "blocked") {
      confirmCloseWithoutSavingModalSingleTab.current?.confirm(blocker.proceed, () => {
        blocker.reset();
        dispatchSidebarMenuTriggerEvent(false);
      });
    }
  }, [blocker.state]);

  const { saveAllInquiriesMutator } = useInquirySaveAll(activeCenterId);

  const handleSaveAllOpenInquiriesClick: ReactEventHandler<HTMLButtonElement> = event => {
    allInquiries.setValue(`inquiries.${activeTabIndex}`, { tabId: activeTab.id, ...inquiryForm.getValues() });
    allInquiries.handleSubmit(({ inquiries: payload }) => {
      saveAllInquiriesMutator.mutate(payload, {
        onSuccess: data => {
          window.toasts.add({ type: "success", content: `Saved ${data.inquiries.length} inquiries successfully!` });
          inquiryForm.setValue("id", data.inquiries[activeTabIndex].id);
          inquiryForm.setValue("callbackSpecialQuoted", data.inquiries[activeTabIndex].callbackSpecialQuoted);
          const inquiryFormFamily = data.inquiries[activeTabIndex].family;
          inquiryForm.setValue("family", inquiryFormFamily);
          inquiryForm.setValue("familyId", inquiryFormFamily?.id);

          data.inquiries.forEach(({ id, callbackSpecialQuoted, family }, index) => {
            allInquiries.setValue(`inquiries.${index}.id`, id);
            allInquiries.setValue(`inquiries.${index}.callbackSpecialQuoted`, callbackSpecialQuoted);
            allInquiries.setValue(`inquiries.${index}.family`, family);
            allInquiries.setValue(`inquiries.${index}.familyId`, family?.id);
          });
          dispatch({ type: "save_all_tabs", payload: { savedInquiries: data.inquiries } });
        }
      });
    })(event);
  };

  const handleAddTab = () => {
    const newTabId = crypto.randomUUID();
    const newTabValues = getDefaultValues();
    dispatch({
      type: "add_tab",
      payload: {
        fromTabValues: inquiryForm.getValues(),
        newTabValues,
        cleanFormValues: newTabValues,
        newTabId
      }
    });
    inquiryTabs.append({ tabId: newTabId });
  };

  const handleInquirySelectorDropdownChange: React.ChangeEventHandler<HTMLSelectElement> = event => {
    dispatch({
      type: "switch_tabs",
      payload: {
        fromTabValues: inquiryForm.getValues(),
        errors: inquiryForm.formState.errors,
        toTabId: event.target.value as UUID
      }
    });
  };

  return (
    <ContentViewport className="slide-in-sidebar">
      <FormProvider {...inquiryForm}>
        <Leftside className="fixed-utility" sizeClasses="col-12 col-lg-7">
          <div className="sticky-top">
            <Utility>
              <div className="d-flex  stack-sm-down w-100 justify-content-between">
                <div>
                  <button className="btn btn-primary" onClick={handleAddTab}>
                    Add Inquiry
                  </button>
                </div>
                <div className="d-flex">
                  <button
                    className="btn btn-secondary me-2"
                    onClick={event => {
                      if (tabState.tabs.length === 1) {
                        confirmCloseWithoutSavingModalSingleTab.current?.confirm(inquiryForm.reset);
                      } else {
                        if (activeTab.isDirty) {
                          confirmCloseWithoutSavingModalMultipleTabs.current?.confirm(() => {
                            dispatch({
                              type: "remove_active_tab"
                            });
                            inquiryTabs.remove(activeTabIndex);
                          });
                        } else {
                          dispatch({
                            type: "remove_active_tab"
                          });
                          inquiryTabs.remove(activeTabIndex);
                        }
                        event.stopPropagation();
                      }
                    }}
                  >
                    Cancel
                  </button>
                  {tabState.tabs.length < 2 && (
                    <button
                      className="btn btn-primary border"
                      id="inquiries-save-dropdown"
                      aria-expanded="false"
                      onClick={handleSaveCurrentInquiry}
                    >
                      Save
                    </button>
                  )}

                  {tabState.tabs.length > 1 && (
                    <div className="dropdown">
                      <button
                        className="btn btn-vcondensed btn-primary border"
                        id="inquiries-save-dropdown"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Save
                      </button>
                      <ul className="dropdown-menu" aria-labelledby="inquiries-save-dropdown">
                        <li>
                          <button
                            className="dropdown-item"
                            disabled={createOrUpdateInquiryMutator.isLoading || saveAllInquiriesMutator.isLoading}
                            onClick={handleSaveCurrentInquiry}
                          >
                            Current Inquiry
                          </button>
                        </li>
                        <li>
                          <button
                            className="dropdown-item"
                            disabled={createOrUpdateInquiryMutator.isLoading || saveAllInquiriesMutator.isLoading}
                            onClick={handleSaveAllOpenInquiriesClick}
                          >
                            All Open Inquiries ({tabState.tabs.length})
                          </button>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </Utility>
            <div className="post-utility-bar">
              <div>
                <h2>{nameGradeHeader}</h2>
              </div>
              <div>
                <a href="#inquiry-form-notes">Go to Notes</a>
                <span className="d-lg-none">
                  &nbsp;|
                  <button className="btn-link" onClick={handleShowDetails}>
                    View Details
                  </button>
                </span>
              </div>
            </div>
          </div>
          <ContentPad className="mh-100">
            {tabState.tabs.length > 1 && (
              <div className="mb-4 d-md-none">
                <div className="form-floating">
                  <select
                    className="form-select"
                    onChange={handleInquirySelectorDropdownChange}
                    id="inquiries-current-inquiry-selector"
                    aria-label="Inquiries"
                  >
                    {inquiriesDropdownOptions}
                  </select>
                  <label htmlFor="inquiries-current-inquiry-selector">Inquiries</label>
                </div>
              </div>
            )}
            <TabContainer className="nav-fill student-tabs d-none d-md-flex">
              {tabState.tabs.map(tab => (
                <NavTab
                  key={tab.id}
                  href={`#tab-${tab.id}`}
                  isActive={tab.id === tabState.activeTabId}
                  onClick={() => {
                    dispatch({
                      type: "switch_tabs",
                      payload: {
                        fromTabValues: inquiryForm.getValues(),
                        errors: inquiryForm.formState.errors,
                        toTabId: tab.id
                      }
                    });
                  }}
                  className="h-100"
                  style={{
                    color:
                      // allInquiries.formState.errors?.inquiries?.[index] ||
                      Object.entries(tab.validationErrors ?? {}).length ? "red" : undefined
                  }}
                >
                  <span className="fas icon fa-user" aria-hidden="true"></span>
                  {tabNameMapper[tab.id]}
                  {tab.isDirty && " *"}
                </NavTab>
              ))}
            </TabContainer>
            <TabContent>
              <div id="new-student" className="tab-pane card active">
                <InquiryFormLeftPane {...leftPaneProps} />
              </div>
            </TabContent>
          </ContentPad>
        </Leftside>
        <Rightside showIf={rightPaneMode !== undefined} sizeClasses="fixed-utility col-12 col-lg-5">
          <InquiryFormRightPane {...rightPaneProps} />
        </Rightside>
        <Confirm
          ref={confirmCloseWithoutSavingModalMultipleTabs}
          title="Confirm"
          message="Would you like to close the Inquiry Form and lose any entered data?"
          okLabel="Yes"
          cancelLabel="No"
          superZ={true}
        />
        <Confirm
          ref={confirmCloseWithoutSavingModalSingleTab}
          size="md"
          title="Confirm"
          message="By cancelling, you will erase all information you have entered to this point and your inquiry will not be saved. Do you wish to continue?"
          okLabel="Confirm"
          cancelLabel="Return to Form"
        />
      </FormProvider>
    </ContentViewport>
  );
};

type UUID = ReturnType<typeof crypto.randomUUID>;

type InquiryTabState = {
  activeTabId: UUID;
  tabs: Array<{
    id: UUID;
    formValues: InquiryFormData;
    cleanFormValues: InquiryFormData;
    isDirty: boolean;
    validationErrors?: FieldErrors<InquiryFormData>;
  }>;
};

type InquiryTabAction =
  | {
      type: "add_tab";
      payload: {
        newTabId: UUID;
        fromTabValues: InquiryFormData;
        newTabValues: InquiryFormData;
        cleanFormValues: InquiryFormData;
      };
    }
  | {
      type: "switch_tabs";
      payload: { fromTabValues: InquiryFormData; toTabId: UUID; errors: FieldErrors<InquiryFormData> };
    }
  | { type: "remove_active_tab" }
  | { type: "save_active_tab"; payload: { savedInquiry: InquiryFormData } }
  | { type: "save_all_tabs"; payload: { savedInquiries: InquiryFormData[] } }
  | { type: "set_active_tab_dirty"; payload: { isDirty: boolean } }
  | { type: "set_active_tab_validation_errors"; payload: { errors: FieldErrors<InquiryFormData> } }
  | { type: "set_all_validation_errors"; payload: { errors: FieldErrors<AllInquiriesType> } };

const tabReducer: Reducer<InquiryTabState, InquiryTabAction> = (state, action) => {
  switch (action.type) {
    case "add_tab": {
      const { newTabId } = action.payload;
      const currentTab = state.tabs.find(({ id }) => id === state.activeTabId);
      invariant(currentTab, `Tab with ID "${state.activeTabId}" not found in tabs.`);
      const { cleanFormValues, fromTabValues } = action.payload;
      const newFormValues = {
        ...cleanFormValues,
        inquirer: fromTabValues.inquirer,
        inquirerId: fromTabValues.inquirerId,
        family: fromTabValues.family,
        familyId: fromTabValues.familyId,
        inquiryDate: cleanFormValues.inquiryDate,
        inquiryTimeReceived: cleanFormValues.inquiryTimeReceived,
        takenBy: cleanFormValues.takenBy,
        id: undefined
      } as InquiryFormData;

      return {
        activeTabId: newTabId,
        tabs: [
          ...state.tabs.map(tab =>
            tab.id === state.activeTabId ? { ...tab, formValues: action.payload.fromTabValues } : tab
          ),
          {
            id: newTabId,
            formValues: newFormValues,
            cleanFormValues,
            isDirty: !isEqual(newFormValues, cleanFormValues),
            validationErrors: currentTab.validationErrors
          }
        ]
      };
    }
    case "switch_tabs":
      const currentTab = state.tabs.find(({ id }) => id === state.activeTabId);
      invariant(currentTab, `Tab with ID "${state.activeTabId}" not found in tabs.`);
      return {
        activeTabId: action.payload.toTabId,
        tabs: state.tabs.map(tab =>
          tab.id === state.activeTabId ? { ...tab, formValues: action.payload.fromTabValues } : tab
        )
      };
    case "remove_active_tab":
      const index = state.tabs.findIndex(tab => tab.id === state.activeTabId);
      const newActiveTab = state.tabs[index + 1] ?? state.tabs[index - 1];
      return {
        activeTabId: newActiveTab.id,
        tabs: [...state.tabs.filter(tab => tab.id !== state.activeTabId)]
      };
    case "save_active_tab":
      const activeTab = state.tabs.find(tab => tab.id === state.activeTabId);
      invariant(activeTab, `Tab with ID "${state.activeTabId}" not found in tabs.`);
      const savedFormValues = { ...activeTab.formValues, id: action.payload.savedInquiry.id };
      return {
        activeTabId: state.activeTabId,
        tabs: state.tabs.map(tab =>
          tab.id === state.activeTabId
            ? {
                id: tab.id,
                formValues: savedFormValues,
                cleanFormValues: savedFormValues,
                // formValues: action.payload.savedInquiry,
                // cleanFormValues: action.payload.savedInquiry,
                isDirty: false
              }
            : tab
        )
      };
    case "save_all_tabs":
      return {
        activeTabId: state.activeTabId,
        tabs: state.tabs.map((tab, index) => ({
          id: tab.id,
          formValues: { ...tab.formValues, id: action.payload.savedInquiries[index].id },
          cleanFormValues: { ...tab.formValues, id: action.payload.savedInquiries[index].id },
          // formValues: action.payload.savedInquiries[index],
          // cleanFormValues: action.payload.savedInquiries[index],
          isDirty: false
        }))
      };
    case "set_active_tab_dirty":
      return {
        activeTabId: state.activeTabId,
        tabs: state.tabs.map(tab => (tab.id === state.activeTabId ? { ...tab, isDirty: action.payload.isDirty } : tab))
      };
    case "set_active_tab_validation_errors":
      return {
        activeTabId: state.activeTabId,
        tabs: state.tabs.map(tab =>
          tab.id === state.activeTabId ? { ...tab, validationErrors: action.payload.errors } : tab
        )
      };
    case "set_all_validation_errors":
      return {
        activeTabId: state.activeTabId,
        tabs: state.tabs.map((tab, index) => ({
          ...tab,
          validationErrors: action.payload.errors.inquiries?.[index] as FieldErrors<AllInquiriesType>
        }))
      };
  }
};

const useDefaultValuesFactory = () => {
  const { profile } = useAccessControl();

  return () => {
    const inquiryStart = moment();

    return {
      ...defaultInquiryFormData,
      inquiryDate: inquiryStart.format("YYYY-MM-DD"),
      inquiryTimeReceived: inquiryStart.format("HH:mm"),
      takenBy: profile
    };
  };
};

export const inquiryFormLoader = () => {
  return {
    activeTab: "Inquiry Management",
    tabQualifier: "Inquiry Form",
    dontSlideInSidebar: true
  };
};
