import {
  type ChangeEventHandler,
  type FocusEventHandler,
  type HtmlHTMLAttributes,
  type MouseEventHandler,
  forwardRef,
  useEffect,
  useId,
  useRef,
  useState
} from "react";
import classNames from "classnames";
import { Dropdown, DropdownMenuProps } from "react-bootstrap";
import { ControllerFieldState, FieldError } from "react-hook-form";

//TODO: we may need to add downshift to this component for some better accessibility support (keyboard navigation)

export interface ComboboxItem {
  label: string;
  value: string | number | null;
  display?: JSX.Element;
  disabled?: boolean;
}

export interface SearchSelectProps {
  onBlur?: () => void;
  onChange?: (value: ComboboxItem) => void;
  selectedItem?: ComboboxItem;
  toggleLabel?: string;
  options?: ComboboxItem[];
  name?: string;
  disabled?: boolean;
  className?: string;
  onSearchChange?: (searchValue: string) => void;
  searchLabel?: string;
  searchValue?: string;
  createNewOptionProps?: {
    onCreateNewClick: () => void;
    label: string;
  };
  fieldState?: ControllerFieldState;
  isLoadingOptions: boolean;
  isRequired?: boolean;
}

export const SearchSelect = forwardRef<HTMLDivElement, SearchSelectProps>(
  (
    {
      onBlur,
      onChange,
      name,
      selectedItem,
      searchLabel = "Search",
      searchValue,
      options = [{ value: "a", label: "a" }],
      onSearchChange,
      createNewOptionProps,
      toggleLabel,
      fieldState,
      isLoadingOptions,
      isRequired,
      disabled
    },
    ref
  ) => {
    const searchInputRef = useRef<HTMLInputElement>(null);
    const id = useId();
    const dropdownId = `dropdown-${name}-${id}`;
    const toggleId = `toggle-${name}-${id}`;
    const menuId = `menu-${name}-${id}`;
    const searchId = `search-${name}-${id}`;
    const listItemId = `list-item-${name}-${id}`;
    const [menuShowing, setMenuShowing] = useState(false);

    const handleSelectItem = (item: ComboboxItem) => {
      onChange?.(item);
    };

    const handleSearchChange: ChangeEventHandler<HTMLInputElement> = event => {
      onSearchChange?.(event.target.value);
    };

    const handleAddNewClick: MouseEventHandler<HTMLLIElement> = event => {
      event.preventDefault();
      createNewOptionProps?.onCreateNewClick();
    };

    const handleShowMenu = (show: boolean) => {
      setMenuShowing(show);

      if (show) {
        searchInputRef.current?.focus();
      }
    };

    const handleBlur: FocusEventHandler<HTMLDivElement> = event => {
      if (!event.currentTarget.contains(event.relatedTarget)) {
        onBlur?.();
      }
    };

    return (
      <>
        <Dropdown onBlur={handleBlur} className="form-input" id={dropdownId} ref={ref}>
          <Dropdown.Toggle
            as={CustomToggle}
            id={toggleId}
            bsPrefix=""
            label={toggleLabel}
            isShown={menuShowing}
            error={fieldState?.error}
            name={name}
            toggleValue={selectedItem?.label ?? `Select a Contact`}
            isRequired={isRequired}
            disabled={disabled}
          />
          <Dropdown.Menu as={CustomMenu} id={menuId} onShow={handleShowMenu}>
            <Dropdown.Item
              onClick={event => {
                event.preventDefault();
                event.stopPropagation();
              }}
              id={`${listItemId}-search`}
            >
              <div className="form-floating has-icon pt-0">
                <input
                  type="text"
                  className="form-control m-1"
                  id={searchId}
                  placeholder={searchLabel}
                  onChange={handleSearchChange}
                  ref={searchInputRef}
                  value={searchValue}
                />
                <label htmlFor={searchId}> {searchLabel} </label>
                <span className="fas fa-search" aria-hidden="true" />
              </div>
              {/* <hr className="mt-0" /> */}
            </Dropdown.Item>
            {createNewOptionProps?.onCreateNewClick && (
              <Dropdown.Item onClick={handleAddNewClick}>
                <span className="fas fa-plus m-2" /> {createNewOptionProps?.label}
              </Dropdown.Item>
            )}
            <div style={{ maxHeight: "300px", overflowY: "auto" }}>
              {searchValue?.trim().length && isLoadingOptions ? (
                <Dropdown.Item disabled>
                  <div className="d-flex align-items-center">
                    <span>Loading...</span>
                    <div className="spinner-border ms-auto" role="status" aria-hidden="true"></div>
                  </div>
                </Dropdown.Item>
              ) : options.length === 0 ? (
                <Dropdown.Item disabled>
                  <span className="m-2 fs-6">No Matching Results</span>
                </Dropdown.Item>
              ) : (
                options.map((option, index) => (
                  <Dropdown.Item
                    key={`${listItemId}-${option.value}-${index}`}
                    active={option.value === selectedItem?.value}
                    onClick={() => handleSelectItem(option)}
                    id={`${listItemId}-${option.value}-${index}`}
                    eventKey={index}
                  >
                    <span className="m-2 fs-6">{option.label}</span>
                  </Dropdown.Item>
                ))
              )}
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  }
);

SearchSelect.displayName = "SearchSelect";

interface CustomToggleProps extends HtmlHTMLAttributes<HTMLDivElement> {
  label?: string;
  isShown?: boolean;
  error?: FieldError;
  name: string;
  toggleValue: string;
  isRequired?: boolean;
  disabled?: boolean;
}
const CustomToggle = forwardRef<HTMLDivElement, CustomToggleProps>(
  ({ onClick, label, id, error, name, toggleValue, isRequired, disabled }, ref) => {
    const hasErrors = !!Object.entries(error ?? {}).length;
    return (
      <div
        className={classNames("border-0 rounded form-floating p-0 pt-0 has-icon d-flex flex-row")}
        onClick={onClick}
        ref={ref}
      >
        <input
          id={id}
          aria-invalid={hasErrors ? "true" : "false"}
          className={classNames("form-control text-left bg-white disabled-custom-toggle", {
            // "is-valid": !error,
            "is-invalid": !disabled && hasErrors
          })}
          placeholder={label}
          value={toggleValue}
          readOnly
          name={name}
          disabled={disabled}
        />
        <label htmlFor={id}>
          {label}
          {isRequired && (
            <>
              {" "}
              <span className="required" aria-hidden="true">
                *
              </span>
            </>
          )}
        </label>
        {/* TODO: invalid icon and chevron take up same space, need to change */}
        {/* <span
          className={classNames("fal", { "fa-chevron-down": !isShown, "fa-chevron-up": isShown })}
          aria-hidden="true"
        /> */}
      </div>
    );
  }
);

CustomToggle.displayName = "CustomToggle";

interface CustomMenuProps extends DropdownMenuProps {
  onShow: (show: boolean) => void;
}
const CustomMenu = forwardRef<HTMLDivElement, CustomMenuProps>(({ show, onShow, ...restProps }, ref) => {
  useEffect(() => {
    onShow(!!show);
  }, [show]);
  return <Dropdown.Menu show={show} {...restProps} ref={ref} />;
});

CustomMenu.displayName = "CustomMenu";
