import { zodResolver } from "@hookform/resolvers/zod";
import classNames from "classnames";
import { useEffect, type FC } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { states } from "../../data/states";
import { useCityAndState } from "../../hooks/use-azure-maps-city-state";
import {
  defaultFamilyContactDetail,
  useFamilyConstants,
  type FamilyContactDetailWithId,
  useFamilyContactDetail,
  FamilyContact
} from "../../hooks/use-families";
import { FormHelpers } from "../forms";
import { InputField } from "../forms/input-field";
import { PhoneNumberField } from "../forms/phone-number-field";
import { SelectField } from "../forms/select-field";
import { useDuplicateContactModal } from "./use-duplicate-contact-modal";
import { isValidOptionalPhoneNumber, zipcodeSchema } from "../forms/form-helpers";
import { ContentPad, Utility } from "../../app/app-layout";

export const QuickCreateStudent: FC<{
  onSave: (contact: FamilyContactDetailWithId) => void;
  onCancel: () => void;
  inquirer?: FamilyContact;
  student?: FamilyContact;
}> = ({ onCancel, onSave, inquirer, student }) => {
  const { activeGrades, activeTitles, activeGenders } = useFamilyConstants();

  const { contactDetail: inquirerDetail } = useFamilyContactDetail("inquirer", inquirer?.id);

  const { contactDetail: studentDetail, createOrUpdateContactMutator } = useFamilyContactDetail("student", student?.id);

  const {
    register,
    reset,
    watch,
    setValue,
    trigger,
    handleSubmit,
    formState: { errors, isSubmitted }
  } = useQuickCreateStudentForm();
  const { renderDuplicateContactModal, onSubmitError } = useDuplicateContactModal({ onSubmit: onSave });

  useEffect(() => {
    reset(studentDetail || defaultFamilyContactDetail);
  }, [studentDetail]);

  // istanbul ignore next
  const handleSave = handleSubmit(formValues => {
    createOrUpdateContactMutator.mutate(
      { id: student?.id, payload: formValues },
      { onSuccess: onSave, onError: onSubmitError }
    );
  });

  // TODO: Figure out reusable generic way to autofill city and state in any form with relevant fields
  const zipcode = watch("zipcode");
  const cityAndStateQuery = useCityAndState(zipcode, {
    enabled: FormHelpers.isValidZipcode(zipcode) && (!watch("city").length || !watch("state").length)
  });

  useEffect(() => {
    if (cityAndStateQuery?.data?.city && cityAndStateQuery?.data?.state) {
      setValue("city", cityAndStateQuery.data.city);
      setValue("state", cityAndStateQuery.data.state);
    }
  }, [cityAndStateQuery.data]);

  useEffect(() => {
    if (isSubmitted) {
      trigger(["email", "homePhone", "personalPhone", "contact_root"]);
    }
  }, [isSubmitted, ...watch(["email", "homePhone", "personalPhone"])]);

  const handleSameAddressChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    if (e.target.checked) {
      setValue("addressLine1", inquirerDetail?.addressLine1 ?? "");
      setValue("addressLine2", inquirerDetail?.addressLine2 ?? "");
      setValue("zipcode", inquirerDetail?.zipcode ?? "");
      setValue("city", inquirerDetail?.city ?? "");
      setValue("state", inquirerDetail?.state ?? "");
    }
  };

  return (
    <>
      <div className="sticky-top">
        <Utility>
          <div className="d-flex  stack-sm-down w-100 justify-content-end">
            <button className="btn btn-secondary me-2" onClick={onCancel}>
              Cancel
            </button>
            <button className="btn btn-primary" onClick={handleSave}>
              Save Student
            </button>
          </div>
        </Utility>
      </div>

      <ContentPad>
        <h2 className="header-small">Student</h2>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col col-12 col-md-6">
                <SelectField disableColClass={true} label="Salutation" {...register("title")}>
                  <option value="">Select Salutation</option>
                  {activeTitles?.map(({ value, name }) => (
                    <option key={value} value={value}>
                      {name}
                    </option>
                  ))}
                </SelectField>
              </div>
              <div className="col col-12 col-md-6 col-lg-12">
                <InputField
                  disableColClass={true}
                  label="First Name"
                  error={errors.firstName}
                  {...register("firstName")}
                />
              </div>
              <div className="col col-12 col-md-6 col-lg-12">
                <InputField
                  disableColClass={true}
                  label="Last Name"
                  error={errors.lastName}
                  {...register("lastName")}
                />
              </div>
              <div className="col col-12 col-md-6">
                <InputField disableColClass={true} label="Suffix" error={errors.suffix} {...register("suffix")} />
              </div>
            </div>

            <h3 className="card-subheader">Info</h3>
            <div className="row">
              <div className="col col-12">
                <SelectField disableColClass={true} label="Gender" {...register("gender")}>
                  <option value="">Select Gender</option>
                  {activeGenders?.map(({ value, name }) => (
                    <option key={value} value={value}>
                      {name}
                    </option>
                  ))}
                </SelectField>
              </div>
              <div className="col col-6">
                <InputField
                  disableColClass={true}
                  label="Age"
                  inputMode="numeric"
                  maxLength={2}
                  type="number"
                  error={errors.age}
                  {...register("age")}
                />
              </div>
              <div className="col col-6">
                <SelectField disableColClass={true} label="Student Grade" {...register("gradeId")}>
                  <option value="">Select Grade</option>
                  {activeGrades?.map(({ value, name }) => (
                    <option key={value} value={value}>
                      {name}
                    </option>
                  ))}
                </SelectField>
              </div>
              <div className="col col-12">
                <InputField disableColClass={true} label="School" error={errors.school} {...register("school")} />
              </div>
            </div>

            <h3
              className={classNames("card-subheader", {
                "is-invalid": errors["contact_root"]
              })}
            >
              Contact
            </h3>
            {!!errors["contact_root"] && (
              // @ts-expect-error
              <div className="invalid-feedback"> {errors["contact_root"]?.message}</div>
            )}
            <div className="row">
              <div className="col col-12">
                <InputField
                  label="Email"
                  disableColClass={true}
                  validationState={errors.email || errors.contact_root ? "error" : undefined}
                  error={errors.email}
                  {...register("email")}
                />
              </div>
              <div className="col col-12">
                <PhoneNumberField
                  label="Home Phone"
                  disableColClass={true}
                  validationState={errors.homePhone || errors.contact_root ? "error" : undefined}
                  error={errors.homePhone}
                  {...register("homePhone")}
                />
              </div>
              <div className="col col-12">
                <PhoneNumberField
                  label="Mobile Phone"
                  disableColClass={true}
                  validationState={errors.personalPhone || errors.contact_root ? "error" : undefined}
                  error={errors.personalPhone}
                  {...register("personalPhone")}
                />
              </div>
            </div>

            <h3 className="card-subheader">Address</h3>
            <div className="row">
              <div className="col col-12">
                <div className="form-check">
                  <label className="form-check-label" htmlFor="sameaddress">
                    Address is the same as the Initial Inquirer
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={handleSameAddressChange}
                    id="sameaddress"
                  />
                </div>
              </div>
              <div className="col col-12">
                <InputField
                  label="Address Line 1"
                  disableColClass={true}
                  error={errors.addressLine1}
                  {...register("addressLine1")}
                />
              </div>
              <div className="col col-12">
                <InputField
                  label="Address Line 2"
                  disableColClass={true}
                  error={errors.addressLine2}
                  {...register("addressLine2")}
                />
              </div>
              <div className="col col-12">
                <InputField label="ZIP" disableColClass={true} error={errors.zipcode} {...register("zipcode")} />
              </div>
              <div className="col col-12">
                <InputField label="City" disableColClass={true} error={errors.city} {...register("city")} />
              </div>
              <div className="col col-12">
                <SelectField label="State" disableColClass={true} {...register("state")}>
                  <option value="">Select State</option>
                  {states.map(({ abbreviation, name }) => (
                    <option key={abbreviation} value={abbreviation}>
                      {name}
                    </option>
                  ))}
                </SelectField>
              </div>
            </div>
          </div>
        </div>
        {renderDuplicateContactModal()}
      </ContentPad>
    </>
  );
};

const nameRegex = /^[a-zA-Z\s\-']+$/;
const suffixRegex = /^[a-zA-Z\s'\-.]+$/;
const addressRegex = /^[a-zA-Z\s'\-.0-9#]+$/;

const invalidCharactersMessage = "contains invalid characters";

export const quickCreateStudentFormSchema = z
  .object({
    title: z.string(),
    firstName: z.string().regex(nameRegex, invalidCharactersMessage).trim().or(z.literal("")),
    lastName: z.string().regex(nameRegex, invalidCharactersMessage).trim().or(z.literal("")),
    suffix: z.string().regex(suffixRegex, invalidCharactersMessage).trim().or(z.literal("")),
    gender: z.string(),

    age: z
      .literal("")
      .transform(() => undefined)
      .or(z.coerce.number())
      .optional()
      .refine(age => !age || age > 0, { message: "must be greater than zero" }),

    gradeId: z.string().optional(),
    school: z.string().regex(addressRegex, invalidCharactersMessage).trim().or(z.literal("")),
    email: z.string().trim().email("invalid email").or(z.literal("")),
    homePhone: z.string().trim().refine(isValidOptionalPhoneNumber, { message: "invalid phone number" }),
    personalPhone: z.string().trim().refine(isValidOptionalPhoneNumber, { message: "invalid phone number" }),
    addressLine1: z.string().regex(addressRegex, invalidCharactersMessage).trim().or(z.literal("")),
    addressLine2: z.string().regex(addressRegex, invalidCharactersMessage).trim().or(z.literal("")),
    zipcode: zipcodeSchema.or(z.literal("")),
    city: z.string().regex(nameRegex, invalidCharactersMessage).trim().or(z.literal("")),
    state: z.string().trim(),
    // TODO: thoughts on sending some of these props grouped together instead of a flattened object?
    // or grouping them up at least on the front end and transforming before sending them out
    // then we wouldnt have to add these random props
    contact_root: z.any()
  })
  .transform(({ age, ...rest }) => ({ ...rest, ...(age ? { age } : {}) }));

export type QuickCreateStudentFormValuesInput = z.input<typeof quickCreateStudentFormSchema>;
export type QuickCreateStudentFormValues = z.infer<typeof quickCreateStudentFormSchema>;

const useQuickCreateStudentForm = () =>
  useForm<QuickCreateStudentFormValuesInput, never, QuickCreateStudentFormValues>({
    defaultValues: defaultFamilyContactDetail,
    resolver: zodResolver(quickCreateStudentFormSchema)
  });
